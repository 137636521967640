<template>
  <v-container class="fill-height d-flex align-center justify-center">
    <v-card class="rounded custom-card" width="400">
      <v-img :src="require('../../assets/Logos/logoNegro.png')" class="mx-3" contain height="200" loading="eager" />
      <!-- <v-card-title class="headline orange-bg bold-text">Enter your credentials:</v-card-title> -->
      <v-card-title class="headline bold-text"
        style="font-family: 'Montserrat', sans-serif; color: white; background: linear-gradient(to right, black, gray);">
        Enter your credentials:
      </v-card-title>
      <v-card-text>
        <form @submit.prevent="submit">
          <v-text-field v-model="username" :error-messages="usernameErrors" label="Username" required
            @input="$v.username.$touch()" @blur="$v.username.$touch()" class="field-width" style="margin-top: 30px;"
            @keyup="convertToUpperCase"></v-text-field>
          <div>
            <!-- <v-text-field v-model="password" :error-messages="passwordErrors" label="Password" required
              @input="$v.password.$touch()" @blur="$v.password.$touch()" type="password" class="field-width"
              @keydown.enter="submit"></v-text-field> -->
            <v-text-field v-model="password" :error-messages="passwordErrors" label="Password" required
              @input="$v.password.$touch()" @blur="$v.password.$touch()" :type="showPassword ? 'text' : 'password'"
              class="field-width" @keydown.enter="submit" :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
              @click:append="togglePasswordVisibility"></v-text-field>

            <v-btn class="mr-4" @click="submit" :class="{ hover: isHovered }" @mouseover="isHovered = true"
              @mouseleave="isHovered = false"
              style="font-family: 'Montserrat', sans-serif; color: white; background-color: black;">
              Login
            </v-btn>
            <!-- <v-btn text class="forgot-text" @click="resetPassword">Forgot your password?</v-btn> -->
            <v-btn text @click="resetPassword" style="font-family: 'Montserrat', sans-serif; color: gray;"
              class="forgot-password-btn">
              Forgot your password?
            </v-btn>
          </div>

          <v-dialog v-model="dialogVisible" max-width="400">
            <v-card>
              <v-card-title class="success-dialog">{{ successMessage }}</v-card-title>
            </v-card>
          </v-dialog>

          <v-dialog v-if="login_count === 0" v-model="showChangePasswordDialog" max-width="400" persistent>
            <v-card>
              <v-card-title>You need to change the password</v-card-title>
              <v-alert type="warning" dense outlined>
                Check your email for the verification code
              </v-alert>
              <v-card-text>
                <v-text-field v-model="employeeNumber" label="Employee Number"></v-text-field>
                <v-text-field v-model="verificationCode" label="Verification Code"
                  @input="clearErrorVerificationCode"></v-text-field>
                <span v-if="verificationCodeMissed" class="error-text">No verification code</span>
                <v-text-field v-model="newPassword" label="New Password"
                  :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showNewPassword ? 'text' : 'password'" @click:append="showNewPassword = !showNewPassword"
                  autocomplete="new-password" @input="clearErrorNewPassword"></v-text-field>
                <span v-if="newPasswordMissed" class="error-text">No new password</span>
                <v-text-field v-model="confirmPassword" label="Confirm Password"
                  :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  @click:append="showConfirmPassword = !showConfirmPassword" @input="clearError"
                  @keydown.enter="savePassword"></v-text-field>
                <span v-if="passwordMismatch" class="error-text">Passwords do not match</span>
              </v-card-text>
              <!-- <v-card-actions>
                <v-btn @click="savePassword">Save</v-btn>
              </v-card-actions> -->
              <v-card-actions class="d-flex justify-end">
                <v-btn color="grey darken-1" text @click="closeDialog">Close</v-btn>
                <v-btn color="primary" @click="savePassword">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="showResetPasswordDialog" max-width="400">
            <v-card>
              <v-card-title>Reset password</v-card-title>
              <v-card-text>
                <v-text-field v-model="employeeNumber" label="Employee Number"></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-btn color="purple" text @click="sendVerificationCode">SEND VERIFICATION CODE</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { bus } from '../../main.js';
import { LOGIN_ENDPOINT, UPDATE_CREDENTIAL, SEND_VERIFICATION_CODE } from '../../utils/endpoints';
import { get_loginCount } from '../../utils/login_count'
export default {
  mixins: [validationMixin],
  validations: {
    username: { required },
    password: { required },
  },
  data: () => ({
    showPassword: false,
    isHoveredForgot: false,
    isHovered: false,
    username: '',
    password: '',
    dialogVisible: false,
    successMessage: '',
    usernameMissed: false,
    verificationCodeMissed: false,
    newPasswordMissed: false,
    login_count: 0,
    showChangePasswordDialog: false,
    showResetPasswordDialog: false,
    employeeNumber: '',
    verificationCode: '',
    newPassword: '',
    confirmPassword: '',
    passwordMismatch: false,
    showNewPassword: false,
    showConfirmPassword: false,
    disableFields: true,
  }),
  computed: {
    usernameErrors() {
      const errors = [];
      if (!this.$v || !this.$v.username || !this.$v.username.$dirty) return errors;
      if (!this.$v.username.required) errors.push('Username is required.');
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v || !this.$v.password || !this.$v.password.$dirty) return errors;
      if (!this.$v.password.required) errors.push('Password is required.');
      return errors;
    },
  },
  mounted() {
    bus.$emit('logout-event');
  },
  methods: {
    closeDialog() {
      this.showChangePasswordDialog = false;
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword; // Alterna entre mostrar/ocultar
    },
    convertToUpperCase() {
      console.log("Tecleando......");
      this.username = this.username.toUpperCase();
    },
    async sendVerificationCodeToEmail() {
      console.log("📤 Sending email verification code...");
      //const url = `http://localhost:5001/generate-verification-code?employee_number=${this.employeeNumber}`;
      const url = `${SEND_VERIFICATION_CODE}${this.employeeNumber}`;
      console.log("🔗 Request URL: ", url);

      try {
        const response = await fetch(url, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
        });

        console.log("🔍 Response Object from Flask: ", response);

        if (!response.ok) {
          let errorMessage = "Backend request failed";
          const errorDetails = await response.text().catch(() => "No additional error details");

          if (response.status === 401) {
            errorMessage = "Incorrect User";
          } else if (response.status === 403) {
            errorMessage = "User is inactive";
          } else if (response.status === 500) {
            errorMessage = "Internal Server Error";
          } else if (response.status === 504) {
            errorMessage = "The server took too long to respond. Try again later.";
          }

          console.error(`❌ Error: ${errorMessage}`, errorDetails);
          return { success: false, error: errorMessage };
        }

        const result = await response.json();
        console.log("✅ Success Response from Flask: ", result);
        return { success: true, data: result };

      } catch (error) {
        console.error("🚨 Fetch Error:", error);
        return { success: false, error: "Network error: Could not reach server." };
      }
    },
    async sendVerificationCode() {
      try {
        console.log("Sending verification code....");
        this.showResetPasswordDialog = false;
        const res = await this.sendVerificationCodeToEmail();
        console.log("res: ", res);
        if (res.ok) {
          this.showChangePasswordDialog = true;
        }
        /* if (res === "ok") {
          this.showChangePasswordDialog = true;
        } */
      } catch (error) {
        console.error("Error/sendVerificationCodeToEmail: ", error)
      }

    },
    resetPassword() {
      console.log("Resetting password...");
      if (this.username === '') {
        this.$v.$touch();
        if (this.$v.$pending || this.$v.$error) {
          return;
        }
      }
      if (this.username === '') {
        this.usernameMissed = true;
      } else {
        this.employeeNumber = this.username
        console.log("username: ", this.username);
        this.showResetPasswordDialog = true;
      }
    },
    async submit() {
      console.log("username: ", this.username);
      this.$v.$touch();
      if (this.$v.$pending || this.$v.$error) {
        return;
      }
      await this.login();
      this.clear();
    },
    async login() {
      const url = LOGIN_ENDPOINT + `?password=${encodeURIComponent(this.password)}&employee_number=${encodeURIComponent(this.username)}`;
      console.log('Attempting to login with URL:', url);

      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        console.log('Received response:', response);

        if (!response.ok) {
          // Manejo de errores HTTP
          if (response.status === 401) {
            this.successMessage = "Incorrect User or Password";
          } else if (response.status === 403) {
            this.successMessage = "User is inactive";
          } else {
            this.successMessage = `Backend request failed: ${response.statusText}`;
          }
          this.dialogVisible = true;
          setTimeout(() => {
            this.dialogVisible = false;
          }, 2000);
          console.error('Backend request failed', response.status, response.statusText);
          return;
        }

        const data = await response.json();
        console.log('Received data:', data);

        if (data.token) {
          console.log('Login successful, received token:', data.token);
          console.log('Login successful, received data_employee:', data.data_employee);
          localStorage.setItem('token', data.token);
          localStorage.setItem('data_employee', JSON.stringify(data.data_employee)); // Convertir a string JSON
          this.login_count = get_loginCount();

          console.log('Login count:', this.login_count);
          if (this.login_count === 0) {
            this.employeeNumber = this.username;
            this.showChangePasswordDialog = true;
            console.log('First login, showing change password dialog');
          } else {
            console.log('Login successful, emitting events and navigating to home');
            bus.$emit('login-success');
            bus.$emit('permissions');
            if (this.$route.path !== '/home') {
              this.$router.push('/home');
            }
            /* this.$router.push({ path: '/home' }); */
          }
        } else {
          this.error = 'Credenciales incorrectas';
          console.error('No token received in response');
        }
      } catch (error) {
        console.error('Error en el inicio de sesión:', error);
        this.error = 'Error en el inicio de sesión';
      } finally {
        // Cualquier limpieza o estado final se puede manejar aquí
        this.loading = false; // Si tienes un indicador de carga
        console.log('Finished login attempt');
      }
    },
    clear() {
      this.$v.$reset();
      this.username = '';
      this.password = '';
    },
    savePassword() {

      if (!this.verificationCode) {
        this.verificationCodeMissed = true;
        return;
      }

      if (!this.newPassword) {
        this.newPasswordMissed = true;
        return;
      }

      if (this.newPassword !== this.confirmPassword) {
        this.passwordMismatch = true;
        return;
      }

      const data = {
        employee_number: this.employeeNumber,
        verification_code: this.verificationCode,
        new_password: this.newPassword,
        login_count: 1
      };

      this.updateEmployeeCredential(data);
    },

    updateEmployeeCredential(data) {
      console.log("Updating Employee Credential......")
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      };

      fetch(UPDATE_CREDENTIAL, requestOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to update employee credential');
          }

        }).then(data => {
          this.showChangePasswordDialog = false;

          console.log('Employee credential updated successfully');

        })
        .catch(error => {
          alert('An error occurred while updating employee credential. Please try again later.');
          console.error('An error occurred while updating employee credential:', error);
        });

    },
    clearError() {
      this.passwordMismatch = false;
    },
    clearErrorVerificationCode() {
      this.verificationCodeMissed = false;
    },
    clearErrorNewPassword() {
      this.newPasswordMissed = false;
    },
    changePassword(data) {
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      };

      fetch(UPDATE_CREDENTIAL, requestOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to update employee credential');
          }
          return response.json();
        })
        .catch(error => {
          alert('An error occurred while updating employee credential. Please try again later.');
        });
    }
  },
}
</script>

<style scoped>
.forgot-password-btn:hover {
  color: black !important;
  /* Cambia el color del texto a negro cuando pases el mouse */
}

.hover {
  background-color: yellow !important;
  color: black !important;
}

.orange-bg {
  background-image: linear-gradient(to right, red, white, yellow);
}

.field-width {
  width: 200px;
}

.bold-text {
  font-weight: 500 !important;
}

.success-dialog {
  color: red;
}

.error-text {
  color: red;
}

.forgot-text {
  margin-top: 8px;
  /* Añadir espacio entre el campo de texto y el mensaje de error */
  color: blue !important;
  cursor: pointer;
  /* Cambia el cursor para indicar que es clicable */
}
</style>