<template>
  <div>
    <v-data-table :headers="headers" :items="filteredEmployees" sort-by="name" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>CRM Permissions</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field v-model="search" label="Search" solo-inverted prepend-icon="mdi-magnify"
            class="mr-3 mt-5"></v-text-field>
          <v-btn color="blue" dark class="mb-2 mr-2" @click="getAllEmployees">
            <v-icon left>mdi-refresh</v-icon>
            Refresh
          </v-btn>
        </v-toolbar>
      </template>

      <template v-for="field in switchFields" v-slot:[`item.${field}`]="{ item }">
        <v-switch :key="field" v-model="item[field]" @change="() => toggleItem(item, field)"
          :color="item[field] ? 'green' : 'red'" :class="item[field] ? 'switch-green' : 'switch-red'"></v-switch>
      </template>
    </v-data-table>

    <div class="loader-container" v-if="showLoader">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
import { UPDATE_PERMISSIONS } from '../../utils/endpoints';
import { getCrmPermissions, updateCrmPermissions } from '../../utils/Permissions/Crm/crm_permissions_CRUD';
import { bus } from '../../main.js';

export default {
  name: 'CrmPermissionsConsole',
  data: () => ({
    showLoader: false,
    employees: [],
    search: '',
    headers: [
      { text: 'Employee number', align: 'start', sortable: false, value: 'employee_number' },
      { text: 'Name(s)', value: 'name' },
      { text: 'Last name(s)', value: 'last_name' },
      { text: 'Department', value: 'department' },
      { text: 'Position', value: 'position' },
      ...[
        'admin_console', 'operator_console', 'marketing', 'others', 'reports'
      ].map(field => ({
        text: field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
        value: field,
        sortable: false
      }))
    ],
    switchFields: [
      'admin_console', 'operator_console', 'marketing', 'others', 'reports'
    ]
  }),
  computed: {
    /* filteredEmployees() {
      return this.employees.filter(employee =>
        employee.name.toLowerCase().includes(this.search.toLowerCase())
      );
    }, */
    filteredEmployees() {
      if (this.employees) {
        return this.employees.filter(employee => {
          const nameMatch = employee.name.toLowerCase().includes(this.search.toLowerCase());
          const lastNameMatch = employee.last_name.toLowerCase().includes(this.search.toLowerCase());
          return nameMatch || lastNameMatch;
        });
      } else {
        return [];
      }
    },
  },
  async mounted() {
    bus.$emit('login-success');
    bus.$emit('permissions');
    await this.getAllEmployees();
  },
  methods: {
    async toggleItem(obj, field) {
      const data = {
        employee_number: obj.employee_number,
        [field]: obj[field]
      };
      await updateCrmPermissions(data);
    },
    async getAllEmployees() {
      this.showLoader = true;
      this.employees = await getCrmPermissions();
      this.showLoader = false;
    },
  },
}
</script>

<style scoped>
.switch-green .v-input--switch__track,
.switch-green .v-input--switch__thumb {
  background-color: #77dd77 !important;
}

.switch-red .v-input--switch__track,
.switch-red .v-input--switch__thumb {
  background-color: #FF9999 !important;
}

.v-data-table th,
.v-data-table td {
  white-space: nowrap;
}

/* Loader */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999;
}

.loader {
  width: 120px;
  height: 60px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  background: #fff;
  filter: blur(5px) contrast(10) hue-rotate(60deg);
  mix-blend-mode: darken;
}

.loader:before,
.loader:after {
  content: "";
  width: 40px;
  border-radius: 50%;
  background: #ff00ff;
  animation: l3 1s infinite alternate;
}

.loader:after {
  --s: -1;
}

@keyframes l3 {

  90%,
  100% {
    transform: translate(calc(var(--s, 1)*30px))
  }
}
</style>
