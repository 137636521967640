<template>
    <div>
        <v-container style="max-width: 1000px; margin: auto;">
            <!-- Formulario de filtros, se muestra solo si el switch está activo -->
            <v-form v-show="showFilters" ref="filterForm" @submit.prevent="search"
                style="background-color: #e0f7fa; padding: 16px; border-radius: 8px;">
                <v-row>
                    <v-col cols="12" sm="6" md="3">
                        <v-row>
                            <v-col>
                                <v-menu v-model="start_date_menu" :close-on-content-click="false"
                                    transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="period.start_date" label="Start Date" readonly
                                            v-bind="attrs" v-on="on" clearable></v-text-field>
                                    </template>
                                    <v-date-picker v-model="period.start_date" color="green lighten-1"
                                        :max="period.end_date" @change="start_date_menu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row v-if="period.start_date != null">
                            <v-col>
                                <v-menu v-model="end_date_menu" :close-on-content-click="false"
                                    transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="period.end_date" label="End Date" readonly v-bind="attrs"
                                            v-on="on" clearable></v-text-field>
                                    </template>
                                    <v-date-picker v-model="period.end_date" color="green lighten-1"
                                        :min="period.start_date" @change="end_date_menu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="6" md="3">
                        <v-row>
                            <v-col>
                                <v-menu v-model="start_time_menu" :close-on-content-click="false"
                                    transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="period.start_time" label="Start Time" readonly
                                            v-bind="attrs" v-on="on" clearable></v-text-field>
                                    </template>
                                    <v-time-picker v-model="period.start_time" @change="start_time_menu = false"
                                        full-width format="24hr" :max="period.end_time"></v-time-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row v-if="period.start_time != null">
                            <v-col>
                                <v-menu v-model="end_time_menu" :close-on-content-click="false"
                                    transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="period.end_time" label="End Time" readonly v-bind="attrs"
                                            v-on="on" clearable></v-text-field>
                                    </template>
                                    <v-time-picker v-model="period.end_time" @change="end_time_menu = false" full-width
                                        format="24hr" :min="period.start_time"></v-time-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-col>
                    <!-- Location -->
                    <v-col cols="12" sm="6" md="3">
                        <v-combobox v-model="locationSelected" :items="locations" item-text="location" label="Location"
                            return-object multiple clearable></v-combobox>
                    </v-col>

                    <v-col cols="12" sm="6" md="3">
                        <v-combobox v-model="filters.reason" :items="labels" label="Reason" return-object multiple
                            clearable></v-combobox>
                    </v-col>

                    <!-- Search Button -->
                    <v-col cols="12" sm="12" md="12" class="d-flex justify-space-between">
                        <div>
                            <v-btn @click="searchFilters" class="mr-4" style="background-color: #1565C0; color: white;">
                                Search
                            </v-btn>
                            <v-btn @click="resetFilters" color="grey lighten-1" class="mr-4">
                                Reset Filters
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-form>
        </v-container>
        <v-data-table :headers="headers" :items="filteredTrainingLogs" sort-by="date_time" :sort-desc="true"
            class="elevation-1">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title class="font-weight-bold">User Walk Ins Console</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-switch v-model="showFilters" label="Filters"></v-switch>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-text-field v-model="search" label="Search" solo-inverted prepend-icon="mdi-magnify"
                        class="mr-3 mt-5"></v-text-field>
                    <v-btn color="blue" dark class="mb-2 mr-2" @click="refreshForms">
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                    <v-btn color="success" @click="openForm" class="mb-2 mr-2">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item, index }">
                <v-icon small class="mr-2" :style="{ color: 'blue' }" @click="preview(item, index)">
                    mdi-eye
                </v-icon>
            </template>
            <!-- Date Formatting -->
            <template v-slot:item.date_time="{ item }">
                <span>{{ formatDate(item.date_time) }}</span>
            </template>

            <!-- Nueva columna "Type" -->
            <template v-slot:item.type="{ item }">
                <span v-if="getWalkInTypes(item).length > 0">
                    <v-chip v-for="(type, index) in getWalkInTypes(item)" :key="index" class="ma-1" color="blue" dark>
                        {{ type }}
                    </v-chip>
                </span>
                <span v-else>-</span>
            </template>
            <template v-slot:item.amount="{ item }">
                <span v-if="item.amount > 0">${{ formatAmount(item.amount) }}</span>
                <span v-else>N/A</span>
            </template>
        </v-data-table>
        <v-dialog content-class="dialog-height" v-model="walkPreview" max-width="900">
            <v-card>
                <WalkInsForm :itemPreview="itemPreview" :name_staff="name_staff" :walkIndex="walkIndex"
                    :walkItem="walkItem" :key="walkPreview.toString()" @sent-info="handleInfoFromChild" />
            </v-card>
        </v-dialog>
        <div class="loader-container" v-if="showLoader">
            <div class="loader"></div>
        </div>
    </div>
</template>

<script>
import { get_employee_number } from '../../utils/get_employee_number.js'
import { GET_WALK_INS_FORMS_BY_EMPLOYEE } from '../../utils/endpoints';
import { bus } from '../../main.js';
import WalkInsForm from '../../components/Walk_ins/WalkInsForm.vue';
import { DateTime } from 'luxon';
import { exportWalkInsToExcel, filterWalkIns, getEmployeesWithWalkIns, getLocations, deleteWalkInByUUID, fetchTodayWalkIns, fetchTodayWalkIns_ByEmployee } from '../../utils/WalkIns/walk_ins_CRUD.js'
import { getEmployeeByNumber } from '../../utils/employees_CRUD.js'

export default {
    components: {
        WalkInsForm
    },
    data: () => ({
        // Filters
        period: {
            end_date: null,
            start_date: null,
            end_time: null,
            start_time: null,
        },
        start_date_menu: false,
        end_date_menu: false,
        start_time_menu: false,
        end_time_menu: false,
        /////////////////
        itemPreview: -1,
        name_staff: '',
        staffSelect: null,
        // Filters
        users: [],
        locations: null,
        labels: [
            "Information",
            "Sold",
            "HR",
            "Copies",
            "Appointment (DMV)",
            "CR + CR Online",
            "Complaint",
            "Payment",
            "Practice WE",
            "Schedule And Reschedule BTW",
            "Teen RT",
            "Documents (TEENS/ADULTS/CHAUFFEURS)",
            "Test Chauffeurs Taxi/Livery",
            "MU Teens"
        ],
        showFilters: false,
        datePickers: {
            creationDateMenu: false,
        },
        filters: {
            creationTime: null,
            creationDate: null,
            location: null,
            staff_name: null,
            reason: null,
        },
        locationSelected: null,
        employeeSelected: null,
        filteredWalkins: null,
        /////////////////
        showLoader: false,
        walkIndex: -1,
        walkItem: null,
        walkPreview: false,
        employee_number: '',
        walk_ins_forms: [],
        search: '',
        headers: [
            { text: 'Creation Date', value: 'date_chicago' },
            { text: 'Creation Time', value: 'time_chicago' },
            { text: 'UUID', value: 'uuid' },
            { text: 'Location', value: 'location' },
            /* { text: 'Creation date', value: 'date_time' }, */
            { text: 'Employee number', value: 'employee_number' },
            { text: 'Staff name', value: 'staff_name' },
            { text: 'Type', value: 'type' }, // Nueva columna
            { text: 'Amount', value: 'amount' }, // Nueva columna
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        editedItem: {
            uuid: '',
            staff_name: '',
            employee_number: '',
            date_time: null
        }

    }),
    computed: {
        filteredTrainingLogs() {
            if (this.walk_ins_forms) {
                return this.walk_ins_forms.filter(walk_in =>
                    walk_in.staff_name.toLowerCase().includes(this.search.toLowerCase())
                );
            }
            else {
                return [];
            }
        },
    },
    watch: {
        'period.start_time'(val) {
            console.log("start_time: ", val);
            if (val != null) {
                //this.period.end_time = '18:00';
            } else {
                this.period.start_time = null;
                this.period.end_time = null;
            }
        },
        'period.start_date'(val) {
            console.log("start_date: ", val);
            if (val != null) {
                //this.period.end_date = val;
            } else {
                this.period.end_date = null;
                this.period.start_date = null;
            }
        },
        locationSelected(newLocations) {
            if (newLocations && newLocations.length) {
                // Mapea las ubicaciones seleccionadas a solo los nombres de las ubicaciones
                this.filters.location = newLocations.map(item => item.location);
            } else {
                // Si no hay ninguna ubicación seleccionada, asegúrate de limpiar el filtro
                this.filters.location = [];
            }
        },
        async showFilters(val) {
            console.log("showFilters/val: ", val);
            if (!val) {
                this.resetFilters();
            } else {
                this.showLoader = true;
                this.users = await getEmployeesWithWalkIns();
                this.locations = await getLocations();
                console.log("users: ", this.users);
                console.log("this.locations: ", this.locations);
                this.showLoader = false;
            }
        },
        walkPreview(val) {
            console.log("walkPreview: ", val);
            if (!val) {
                this.walkItem = null;
                this.walkIndex = -1;
                this.itemPreview = -1;
            }
        }
    },
    async mounted() {
        console.log("-------------------MOUNTED.......................")
        bus.$emit('login-success');
        bus.$emit('permissions');
        this.employee_number = get_employee_number();
        console.log("employee_number: ", this.employee_number);
        this.walk_ins_forms = await this.get_WalkIns_Forms();
        console.log("this.walk_ins_forms: ", this.walk_ins_forms);
        console.log("Walk ins: ", this.walk_ins_forms);

        this.staffSelect = await getEmployeeByNumber(this.employee_number);//this.get_EmployeeData()
        console.log("this.staffSelect: ", this.staffSelect);
        this.name_staff = `${this.staffSelect.name} ${this.staffSelect.last_name}`;
        console.log("this.name_staff: ", this.name_staff);

    },
    methods: {
        formatAmount(value) {
            if (isNaN(value) || value === null) return '$0.00';
            return parseFloat(value).toFixed(2);
        },
        getWalkInTypes(item) {
            const typeMapping = {
                information: "Information",
                sold: "Sold",
                hr: "HR",
                copies: "Copies",
                appointment_dmv: "Appointment (DMV)",
                cr_cr_online: "CR + CR Online",
                complaint: "Complaint",
                payment: "Payment",
                practice_we: "Practice WE",
                schedule_reschedule_btw: "Schedule And Reschedule BTW",
                teen_rt: "Teen RT",
                documents_teens_adults_chauffeurs: "Documents (TEENS/ADULTS/CHAUFFEURS)",
                test_chauffeurs_taxi_livery: "Test Chauffeurs Taxi/Livery",
                mu_teens: "MU Teens"
            };

            return Object.keys(typeMapping)
                .filter(key => item[key]) // Filtrar los valores que son `true`
                .map(key => typeMapping[key]); // Convertir las claves en nombres amigables
        },
        resetFilters() {
            this.period.end_time = null;
            this.period.start_time = null;
            this.period.end_date = null;
            this.period.start_date = null;
            this.locationSelected = null;
            //this.employeeSelected = null;
            this.filters = {
                creationDate: null,
                location: null,
                staff_name: null,
                reason: null,
            };
        },
        async searchFilters() {
            this.showLoader = true;
            if (this.period.end_time === null && this.period.start_time != null) {
                this.period.end_time = '23:59';
                console.log("this.period.end_time: ", this.period.end_time);
            }
            if (this.period.end_date === null && this.period.start_date !== null) {
                //this.period.end_date = this.period.start_date
                const today = new Date();
                const yyyy = today.getFullYear();
                const mm = String(today.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son de 0 a 11, por eso sumamos 1
                const dd = String(today.getDate()).padStart(2, '0');

                // Formato yyyy-mm-dd
                this.period.end_date = `${yyyy}-${mm}-${dd}`;
            }
            this.filters.creationDate = [this.period.start_date, this.period.end_date];
            this.filters.creationTime = [this.period.start_time, this.period.end_time];
            // Aquí se realiza la búsqueda filtrada            
            console.log("filters: ", this.filters);
            this.filters.staff_name = this.name_staff;
            this.walk_ins_forms = await filterWalkIns(this.filters);
            console.log("Walk ins: ", this.walk_ins_forms);
            this.showLoader = false;
        },
        async handleInfoFromChild(info) {
            console.log("info usser walk ins: ", info);
            this.walkPreview = info; //Close dialog
            this.walk_ins_forms = await this.get_WalkIns_Forms();
            console.log("this.walk_ins_forms: ", this.walk_ins_forms);
            this.walkItem = null;
            this.walkIndex = -1;
        },
        openForm() {
            console.log("Opening Form Dialog");
            this.walkPreview = true;
        },
        formatDate(date) {
            const chicagoTime = DateTime.fromISO(date, { zone: 'utc' }).setZone('America/Chicago');
            // Format the date in 'yyyy-MM-dd HH:mm:ss' format
            return chicagoTime.toFormat('yyyy-MM-dd HH:mm:ss');
        },
        preview(item, index) {
            console.log("########## Preview......");
            console.log("item: ", item);
            console.log("index: ", index);
            this.walkPreview = true;
            this.walkIndex = index;
            this.walkItem = item;
            this.itemPreview = index;
        },
        async refreshForms() {
            this.walk_ins_forms = await this.get_WalkIns_Forms();
        },
        async get_WalkIns_Forms() {
            try {
                this.showLoader = true;
                console.log("Getting training logs.....")
                console.log("employee_number: ", this.employee_number);

                //const url = `${GET_WALK_INS_FORMS_BY_EMPLOYEE}${this.employee_number}`;
                //const response = await fetch(url); // Replace "your_endpoint" with the URL of your endpoint

                const data = await fetchTodayWalkIns_ByEmployee(this.employee_number);
                console.log("data: ", data);

                if (data.length === 0) {
                    this.showLoader = false;
                    return [];
                    //throw new Error('Failed to fetch record');
                }

                //const res = await response.json();
                //const data = response;
                //console.log("<<<<<<<<<<<<<<<<<<< res data: ", data);
                this.showLoader = false;
                return data;
            } catch (error) {
                this.showLoader = false;
                console.error('Error fetching record:', error);
            }
        },
    },
}
</script>

<style scoped>
.loader-container {
    position: fixed;
    /* Para que el loader esté por encima de todo el contenido */
    top: 0;
    left: 0;
    width: 100%;
    /* Ocupa todo el ancho del viewport */
    height: 100%;
    /* Ocupa todo el alto del viewport */
    display: flex;
    justify-content: center;
    /* Centra horizontalmente */
    align-items: center;
    /* Centra verticalmente */
    background-color: rgba(255, 255, 255, 0.5);
    /* Fondo semitransparente */
    z-index: 9999;
    /* Coloca el loader por encima de todo el contenido */
}

.loader {
    width: 120px;
    height: 60px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background: #fff;
    filter: blur(5px) contrast(10) hue-rotate(60deg);
    mix-blend-mode: darken;
}

.loader:before,
.loader:after {
    content: "";
    width: 40px;
    border-radius: 50%;
    background: #ff00ff;
    animation: l3 1s infinite alternate;
}

.loader:after {
    --s: -1;
}

@keyframes l3 {

    90%,
    100% {
        transform: translate(calc(var(--s, 1)*30px))
    }
}
</style>