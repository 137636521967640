<template>
    <div>
        <v-container style="max-width: 1000px; margin: auto;">
            <!-- Formulario de filtros, se muestra solo si el switch está activo -->
            <v-form v-show="showFilters" ref="filterForm" @submit.prevent="search"
                style="background-color: #e0f7fa; padding: 16px; border-radius: 8px;">
                <v-row>
                    <v-col cols="12" sm="6" md="3">
                        <v-row>
                            <v-col>
                                <v-menu v-model="start_date_menu" :close-on-content-click="false"
                                    transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="period.start_date" label="Start Date" readonly
                                            v-bind="attrs" v-on="on" clearable></v-text-field>
                                    </template>
                                    <v-date-picker v-model="period.start_date" color="green lighten-1"
                                        :max="period.end_date" @change="start_date_menu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                        <v-row v-if="period.start_date != null">
                            <v-col>
                                <v-menu v-model="end_date_menu" :close-on-content-click="false"
                                    transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="period.end_date" label="End Date" readonly v-bind="attrs"
                                            v-on="on" clearable></v-text-field>
                                    </template>
                                    <v-date-picker v-model="period.end_date" color="green lighten-1"
                                        :min="period.start_date" @change="end_date_menu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12" sm="6" md="3">
                        <v-combobox v-model="employeeSelected" :items="usersWithFullName" item-text="fullName"
                            label="Staff Name" return-object clearable multiple></v-combobox>
                    </v-col>

                    <v-col cols="12" sm="6" md="3">
                        <v-combobox v-model="filters.reason" :items="reasons" label="Reason" return-object multiple
                            clearable></v-combobox>
                    </v-col>
                </v-row>
                <v-row>
                    <!-- Search Button -->
                    <v-col cols="12" sm="12" md="12" class="d-flex justify-space-between">
                        <div>
                            <v-btn @click="searchFilters" class="mr-4" style="background-color: #1565C0; color: white;">
                                Search
                            </v-btn>
                            <v-btn @click="resetFilters" color="grey lighten-1" class="mr-4">
                                Reset Filters
                            </v-btn>
                            <!-- <v-btn @click="test" color="grey lighten-1" class="mr-4">
                                test
                            </v-btn> -->
                        </div>
                        <div>
                            <v-btn @click="exportReport" class="ml-4" style="background-color: #28a745; color: white;">
                                Export to Excel
                            </v-btn>
                        </div>
                    </v-col>
                </v-row>
            </v-form>
        </v-container>
        <v-data-table v-if="todayActivities != null" :headers="headers" :items="filteredActivities"
            sort-by="created_time" :sort-desc="true" class="elevation-1">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title class="font-weight-bold">Admin Report Activities</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-switch v-model="showFilters" label="Filters"></v-switch>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-text-field v-model="search" label="Search" solo-inverted prepend-icon="mdi-magnify"
                        class="mr-3 mt-5"></v-text-field>
                    <v-btn color="blue" dark class="mb-2 mr-2" @click="refreshActivities">
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:item.employee_data="{ item }">
                {{ `${item.employee_data.name} ${item.employee_data.last_name}` }}
            </template>
            <template v-slot:item.actions="{ item, index }">
                <v-icon large class="mr-2" :style="{ color: 'blue' }" @click="viewHistory(item, index)">
                    mdi-calendar-clock
                </v-icon>
                <v-icon v-show="item.summary && Object.keys(item.summary).length > 0" large class="mr-2"
                    :style="{ color: 'green' }" @click="viewSummary(item, index)">
                    mdi-file-chart-outline
                </v-icon>
            </template>
            <!-- Muestra el total de Calls -->
            <template v-slot:item.calls="{ item }">
                {{ calculateCalls(item.activities) }}
            </template>

            <!-- Muestra el total de Walk Ins -->
            <template v-slot:item.walkIns="{ item }">
                {{ calculateWalkIns(item.activities) }}
            </template>

            <!-- Columna Sales: pie de columna con la sumatoria -->
            <template v-slot:footer.sales>
                <strong>Total Sales: {{ formatCurrency(totalSales) }}</strong>
            </template>

            <!-- Columna Sales: total de la propiedad sale de todas las actividades -->
            <template v-slot:item.sales="{ item }">
                <span>{{ formatCurrency(calculateSales(item.activities)) }}</span>
            </template>
        </v-data-table>
        <v-data-table v-if="activitiesFilteredReason != null" :headers="headersActivities"
            :items="activitiesFilteredReason" class="elevation-1">
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title class="font-weight-bold">Admin Report Activities</v-toolbar-title>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-switch v-model="showFilters" label="Filters"></v-switch>
                    <v-divider class="mx-4" inset vertical></v-divider>
                    <v-text-field v-model="search" label="Search" solo-inverted prepend-icon="mdi-magnify"
                        class="mr-3 mt-5"></v-text-field>
                    <v-btn color="blue" dark class="mb-2 mr-2" @click="refreshActivities">
                        <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:[`item.employee_data`]="{ item }">
                {{ item.employee_data.name }} {{ item.employee_data.last_name }}
            </template>
        </v-data-table>
        <div class="loader-container" v-if="showLoader">
            <div class="loader"></div>
        </div>
        <!-- Dialogo para mostrar historial -->
        <v-dialog v-model="dialog" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="text-h6">Activities</span>
                </v-card-title>
                <v-card-subtitle class="mt-1">
                    <div>
                        <p style="margin: 0;"><strong>Employee:</strong> {{ selectedEmployee.name }} {{
                            selectedEmployee.last_name }}</p>
                        <p style="margin: 0;"><strong>Date:</strong> {{ selectedDate }}</p>
                    </div>
                </v-card-subtitle>
                <v-card-text>
                    <!-- Tabla con historial -->
                    <v-data-table :headers="dynamicHeaders" :items="selectedActivities" class="elevation-1">
                        <template v-slot:item.sale="{ item }">
                            <span>{{ formatCurrency(item.sale) }}</span>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <!-- Dialogo para mostrar detalles del resumen -->
        <v-dialog v-model="dialogSummary" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="text-h6">Summary</span>
                </v-card-title>
                <v-card-text>
                    <!-- Validar si hay datos en summary -->
                    <div v-if="selectedSummary">
                        <p style="margin: 0;"><strong>Employee:</strong> {{ selectedEmployee.name }} {{
                            selectedEmployee.last_name }}</p>
                        <p><strong>Date:</strong> {{ selectedSummary.Date }}</p>
                        <v-data-table :headers="summaryHeaders" :items="summaryData" class="elevation-1"
                            dense></v-data-table>
                    </div>
                    <div v-else>
                        <p>No hay datos de resumen disponibles.</p>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>


import { bus } from '../../main.js';
import { fetchALLTodayActivities, fetchUniqueExtensions, fetchActivitiesWithReason, exportActivitiesToExcel, fetchTodayActivitiesByDepartment } from '../../utils/Sales/activity_report_CRUD.js'
import { fetchActiveEmployees } from '../../utils/employees_CRUD.js'
import { get_employee_number } from '../../utils/get_employee_number.js'
import { getEmployeeByNumber, fetchEmployeesByDepartment } from '../../utils/employees_CRUD.js'

export default {
    components: {

    },
    data: () => ({
        position: '',
        department: '',
        extensions: [],
        extensionSelected: '',
        rowNames: [
            "Date",
            "Extension",
            "Clock In",
            "Go to Lunch",
            "Back Lunch",
            "Calls",
            "Admin Tasks",
            "Info/Sold other",
            "Info/Sold mine",
            "Info/Not Sold",
            "Outbound FU",
            "Schedule",
            "Complaint",
            "Other",
            "Outbound MC",
            "Chats",
            "Walk Ins",
            "Collected Payment Mine",
            "Collected Payment Other",
            "Collected Payment Total",
            "Clock Out",
        ],
        rowNamesCalendar: [
            'Date',
            'Extension',
            'Clock In',
            'Go to Lunch',
            'Back Lunch',
            'Calls',
            "Admin Tasks",
            'Inbound',
            'Outbound',
            'Walk Ins',
            'Last minute Cancellation',
            'Rescheduling',
            'Follow-up',
            'Instructor`s Sale',
            'Road Test Instructor Support',
            'Instructor Support',
            'CHAT - Instructors Chats',
            'CHAT - Instructors Chats + Google Chats',
            'CHAT - Same Day Chat',
            'CHAT - Road Test & Appointments',
            'CHAT - 8x8',
            'CHAT - 8x8 + Instructor`s Chats',
            'EMAIL-SMS - Expired Credits',
            'EMAIL-SMS - New Online Registration',
            'EMAIL-SMS - Web BTW',
            'EMAIL-SMS - Road Test Support',
            'EMAIL-SMS - Student Schedule',
            'EMAIL-SMS - Form Submission',
            'EMAIL-SMS - Department Collaboration Request',
            'Clock Out'
        ],
        rowNamesMarketing: [
            'Date',
            'Extension',
            'Clock In',
            'Go to Lunch',
            'Back Lunch',
            "Admin Tasks",
            'Clock Out'
        ],
        summaryRows: [],
        dialogSummary: false, // Controla la visibilidad del diálogo
        selectedSummary: null, // Datos del resumen seleccionado
        summaryHeaders: [
            { text: "Key", value: "key" },
            { text: "Value", value: "value" },
        ],
        dialog: false, // Controla la visibilidad del diálogo
        headersActivities: [
            { text: "Created Date", value: "created_date" },
            { text: "Time", value: "time" },
            { text: "Employee", value: "employee_data" },
            { text: "Reason", value: "reason" },
            { text: "Reason Not Sold", value: "reason_not_sold" },
            { text: "Instructor", value: "instructor" },
            { text: "Type of activity", value: "type_of_call.type" },
            { text: "Notes", value: "notes" },
        ],
        selectedActivities: [], // Historial seleccionado
        selectedEmployee: {}, // Datos del empleado
        selectedDate: "", // Fecha seleccionada
        todayActivities: [],
        // Filters
        period: {
            end_date: null,
            start_date: null,
            end_time: null,
            start_time: null,
        },
        start_date_menu: false,
        end_date_menu: false,
        start_time_menu: false,
        end_time_menu: false,
        ///////////
        itemPreview: -1,
        name_staff: '',
        //editIndex: -1,
        // Delete Dialog
        dialogDelete: false,
        itemToDelete: null,
        ///////
        employeeSelectedId: null,
        showLoader: false,
        // Filters
        locations: null,
        users: [],
        reasons: [
            "Info/Sold other",
            "Info/Sold mine",
            "Info/Not Sold",
            "Outbound FU",
            "Personal FU",
            "Schedule",
            "Complaint",
            "Other",
            "Outbound MC",
            "CHAT",
            "Walk In",
            "EMAIL-SMS",

        ],
        showFilters: false,
        datePickers: {
            creationDateMenu: false,
        },
        filters: {
            creationTime: null,
            creationDate: null,
            //location: null,
            staff_name: null,
            reason: null,
            department: null,
        },
        locationSelected: null,
        employeeSelected: null,

        employee_number: '',
        search: '',
        location: '',
        headers: [
            { text: 'Created Date', value: 'created_date' },
            { text: 'Extension', value: 'extension' },
            { text: 'Employee', value: 'employee_data' },
            { text: 'Department', value: 'employee_data.department' },
            { text: '# Calls', value: 'calls' },
            { text: '# Walk Ins', value: 'walkIns' },
            { text: "Sales", value: "sales" },
            { text: 'Actions', value: 'actions', sortable: false },
        ],
        activitiesFilteredReason: null,
    }),
    watch: {
        'period.start_time'(val) {
            console.log("start_time: ", val);
            if (val != null) {
                //this.period.end_time = '18:00';
            } else {
                this.period.start_time = null;
                this.period.end_time = null;
            }
        },
        'period.start_date'(val) {
            console.log("start_date: ", val);
            if (val != null) {
                //this.period.end_date = val;
            } else {
                this.period.end_date = null;
                this.period.start_date = null;
            }
        },
        'filters.creationDate'(val) {
            console.log("filters.creationDate: ", val);
        },

        async showFilters(val) {
            console.log("showFilters/val: ", val);
            if (!val) {
                this.resetFilters();
            } else {
                this.showLoader = true;

                if (this.filters.department === null) {
                    this.users = await fetchActiveEmployees();
                } else {
                    this.users = await fetchEmployeesByDepartment(this.department, 'Active');
                }

                //this.locations = await getLocations();
                console.log("users: ", this.users);
                //console.log("this.locations: ", this.locations);
                this.showLoader = false;
            }
        },
        locationSelected(newLocations) {
            if (newLocations && newLocations.length) {
                // Mapea las ubicaciones seleccionadas a solo los nombres de las ubicaciones
                this.filters.location = newLocations.map(item => item.location);
            } else {
                // Si no hay ninguna ubicación seleccionada, asegúrate de limpiar el filtro
                this.filters.location = [];
            }
        },
        employeeSelected(newEmployees) {
            console.log("newEmployees: ", newEmployees);
            if (newEmployees && newEmployees.length) {
                // Mapea las ubicaciones seleccionadas a solo los nombres de las ubicaciones
                this.filters.staff_name = newEmployees.map(item => item.fullName);
            } else {
                // Si no hay ninguna ubicación seleccionada, asegúrate de limpiar el filtro
                this.filters.staff_name = [];
            }
            /* if (item) {
                this.filters.staff_name = item.fullName;
            } */
        }
    },
    computed: {
        dynamicHeaders() {
            // Verifica si existe al menos un instructor válido
            const hasInstructor = this.selectedActivities.some(activity =>
                activity.instructor && activity.instructor.trim() !== '' // Verifica que no sea nulo, indefinido o cadena vacía
            );

            // Filtra los encabezados dinámicamente
            return this.headersActivities.filter(
                header => hasInstructor || header.value !== 'instructor' // Incluye la columna instructor solo si es necesario
            );
        },
        usersWithFullName() {
            // Asegurarse de que users esté definido y sea un array
            return this.users.length ? this.users.map(user => ({
                ...user,
                fullName: `${user.name} ${user.last_name}`  // Concatenar name y last_name
            })) : [];
        },
        filteredActivities() {
            if (this.todayActivities) {
                return this.todayActivities.filter(activity => {
                    const staffMatch = (activity.employee_data.name || '').toLowerCase().includes(this.search.toLowerCase());
                    return staffMatch;
                });
            } else {
                return [];
            }
        },
    },
    async mounted() {
        console.log("-------------------MOUNTED.......................")
        bus.$emit('login-success');
        bus.$emit('permissions');
        this.employee_number = get_employee_number();
        console.log("employee_number: ", this.employee_number);

        this.staffSelect = await getEmployeeByNumber(this.employee_number);//this.get_EmployeeData()
        console.log("this.staffSelect: ", this.staffSelect);
        this.department = this.staffSelect.department;
        console.log("this.department: ", this.department);

        this.position = this.staffSelect.position;
        console.log("this.position: ", this.position);

        if (this.position === 'Manager' || this.position === 'Director') {
            this.todayActivities = await fetchALLTodayActivities();
        } else {
            this.filters.department = this.department;
            this.todayActivities = await fetchTodayActivitiesByDepartment(this.department)
        }

        //this.extensions = await fetchUniqueExtensions();

        //console.log("extensions: ", this.extensions);
        console.log("todayActivities: ", this.todayActivities);
    },
    methods: {
        calculateSales(activities) {
            return activities.reduce((total, activity) => {
                return total + (parseFloat(activity.sale) || 0);
            }, 0);
        },
        formatCurrency(value) {
            const number = parseFloat(value) || 0;
            return `$ ${number}`;
        },
        calculateCalls(activities) {
            return activities.reduce((total, activity) => {
                if (activity.type_of_call && ['INBOUND', 'OUTBOUND'].includes(activity.type_of_call.type)) {
                    return total + 1;
                }
                return total;
            }, 0);
        },
        calculateWalkIns(activities) {
            return activities.reduce((total, activity) => {
                if (activity.reason && activity.reason.toLowerCase().includes('walk in')) {
                    return total + 1;
                }
                return total;
            }, 0);
        },
        async refreshActivities() {
            //this.todayActivities = await fetchALLTodayActivities();
            if (this.position === 'Manager' || this.position === 'Director') {
                this.todayActivities = await fetchALLTodayActivities();
            } else {
                this.todayActivities = await fetchTodayActivitiesByDepartment(this.department)
            }
            this.activitiesFilteredReason = null;
        },
        viewHistory(item, index) {
            console.log("item: ", item);

            // Actualiza los datos seleccionados
            this.selectedActivities = item.activities || []; // Historial de actividades
            console.log("this.selectedActivities: ", this.selectedActivities);
            this.selectedEmployee = item.employee_data || {}; // Datos del empleado
            this.selectedDate = item.created_date || ""; // Fecha de creación
            this.headersActivities = [
                { text: "Time", value: "time" },
                { text: "Reason", value: "reason" },
                { text: "Reason Not Sold", value: "reason_not_sold" },
                { text: "Instructor", value: "instructor" },
                { text: "Type of activity", value: "type_of_call.type" },
                { text: "Sale", value: "sale" },
                { text: "Notes", value: "notes" },
            ]

            // Abre el diálogo
            this.dialog = true;
        },
        viewSummary(item, index) {
            console.log("Viewing summary for:", item, "at index:", index);

            // Verifica si la propiedad summary existe
            if (item.summary && Object.keys(item.summary).length > 0) {
                this.selectedSummary = item.summary;
                this.selectedEmployee = item.employee_data || {}; // Datos del empleado

                let rows = null;
                if (item.employee_data.department === 'Sales Department') {
                    rows = this.rowNames;
                } else if (item.employee_data.department === 'Calendar Department') {
                    rows = this.rowNamesCalendar;
                } else {
                    rows = this.rowNamesMarketing
                }

                // Filtra las filas usando rowNames
                this.summaryData = Object.entries(item.summary)
                    .filter(([key]) => rows.includes(key)) // Solo incluir claves en rowNames
                    .map(([key, value]) => ({
                        key,
                        value,
                    }));

                // Abre el diálogo
                this.dialogSummary = true;
            } else {
                console.log("No summary data available.");
                this.selectedSummary = null;
                this.dialogSummary = false;
            }
        },
        test() {
            console.log("period: ", this.period);
        },
        async handleInfoFromChild(info) {
            console.log("info: ", info);
            this.walkPreview = info; //Close dialog
            this.walk_ins_forms = await this.get_WalkIns_Forms();
            console.log("this.walk_ins_forms: ", this.walk_ins_forms);
            this.walkItem = null;
            this.walkIndex = -1;
        },
        editItem(item) {
            console.log("item_to_Edit: ", item);
            this.walkPreview = true;
            this.walkIndex = item.walk_in_id;
            this.walkItem = item;
            //this.editIndex = item.walk_in_id;
            this.name_staff = item.staff_name;
        },
        confirmDelete(item) {
            // Abre el diálogo y almacena el ítem a eliminar
            this.itemToDelete = item;
            this.dialogDelete = true;
        },
        async confirmDeleteAction() {
            try {
                this.showLoader = true;
                console.log("itemToDelete: ", this.itemToDelete);
                const res = await deleteWalkInByUUID(this.itemToDelete.uuid);
                console.log("res: ", res);
                if (res.ok) {
                    this.walk_ins_forms = await this.get_WalkIns_Forms();
                    // Cierra el diálogo
                    this.dialogDelete = false;
                    this.itemToDelete = null;
                    this.showLoader = false;
                }
            } catch (error) {
                // Cierra el diálogo
                this.dialogDelete = false;
                this.itemToDelete = null;
                this.showLoader = false;
                console.error("Error deleteItem: ", error);
            }
        },
        async searchFilters() {
            this.showLoader = true;
            if (this.period.start_date === null) {
                const chicagoTime = new Date().toLocaleDateString('en-US', { timeZone: 'America/Chicago' });
                //this.period.start_date = chicagoTime;
                const [month, day, year] = chicagoTime.split("/");
                this.period.start_date = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
            }
            if (this.period.end_date === null && this.period.start_date !== null) {
                //this.period.end_date = this.period.start_date
                const today = new Date();
                const yyyy = today.getFullYear();
                const mm = String(today.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript son de 0 a 11, por eso sumamos 1
                const dd = String(today.getDate()).padStart(2, '0');

                // Formato yyyy-mm-dd
                this.period.end_date = `${yyyy}-${mm}-${dd}`;
            }
            this.filters.creationDate = [this.period.start_date, this.period.end_date];
            this.filters.creationTime = [this.period.start_time, this.period.end_time];
            console.log("filters: ", this.filters);

            if (this.filters.reason === null || this.filters.reason.length === 0) {
                this.todayActivities = await fetchActivitiesWithReason(this.filters);
                this.activitiesFilteredReason = null;
                console.log("this.todayActivities: ", this.todayActivities)
            } else {
                this.headersActivities = [
                    { text: "Created Date", value: "created_date" },
                    { text: "Time", value: "time" },
                    { text: "Employee", value: "employee_data" },
                    { text: "Reason", value: "reason" },
                    { text: "Reason Not Sold", value: "reason_not_sold" },
                    { text: "Type of call", value: "type_of_call.type" },
                    { text: "Notes", value: "notes" },
                ];
                this.todayActivities = null;
                this.activitiesFilteredReason = await fetchActivitiesWithReason(this.filters);
                console.log("this.activitiesFilteredReason: ", this.activitiesFilteredReason)
            }
            this.showLoader = false;
        },
        async exportReport() {
            try {
                this.showLoader = true;
                //exportWalkInsToExcel(this.walk_ins_forms);
                console.log("this.filters: ", this.filters);
                if (this.filters.reason != null && this.filters.reason.length > 0) {
                    console.log("with reason");
                    //await exportActivitiesToExcel(this.activitiesFilteredReason);
                    console.log("this.activitiesFilteredReason: ", this.activitiesFilteredReason);
                    await exportActivitiesToExcel(this.filters);
                } else {
                    console.log("with no reason");
                    //await exportActivitiesToExcel(this.todayActivities);
                    await exportActivitiesToExcel(this.filters);
                }
                this.showLoader = false;
            } catch (error) {
                this.showLoader = false;
                console.error("exportReport/Error: ", error);
            }

        },
        resetFilters() {
            this.period.end_date = null;
            this.period.start_date = null;
            this.employeeSelected = null;
            this.filters.creationDate = null;
            this.filters.staff_name = null;
            this.filters.reason = null;
            /* this.filters = {
                creationDate: null,
                staff_name: null,
                reason: null,
                department: null,
            }; */
        },
        formatDate(date) {
            const chicagoTime = DateTime.fromISO(date, { zone: 'utc' }).setZone('America/Chicago');
            // Format the date in 'yyyy-MM-dd HH:mm:ss' format
            return chicagoTime.toFormat('yyyy-MM-dd HH:mm:ss');
        },
        preview(item, index) {
            console.log("########## Preview......");
            console.log("item: ", item);
            console.log("index: ", index);
            this.name_staff = item.staff_name;
            this.walkPreview = true;
            this.itemPreview = index;
            //this.walkIndex = index;
            this.walkItem = item;
            //this.preview
        },
    },
}
</script>

<style scoped>
.loader-container {
    position: fixed;
    /* Para que el loader esté por encima de todo el contenido */
    top: 0;
    left: 0;
    width: 100%;
    /* Ocupa todo el ancho del viewport */
    height: 100%;
    /* Ocupa todo el alto del viewport */
    display: flex;
    justify-content: center;
    /* Centra horizontalmente */
    align-items: center;
    /* Centra verticalmente */
    background-color: rgba(255, 255, 255, 0.5);
    /* Fondo semitransparente */
    z-index: 9999;
    /* Coloca el loader por encima de todo el contenido */
}

.loader {
    width: 120px;
    height: 60px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background: #fff;
    filter: blur(5px) contrast(10) hue-rotate(60deg);
    mix-blend-mode: darken;
}

.loader:before,
.loader:after {
    content: "";
    width: 40px;
    border-radius: 50%;
    background: #ff00ff;
    animation: l3 1s infinite alternate;
}

.loader:after {
    --s: -1;
}

@keyframes l3 {

    90%,
    100% {
        transform: translate(calc(var(--s, 1)*30px))
    }
}
</style>