<template>
    <div>
        <v-row class="pt-4 px-4">
            <v-col cols="12" sm="12" md="12" class="d-flex justify-center">
                <v-card height="auto">
                    <v-card-text>
                        <v-data-table :headers="headers" :items="filteredItems" sort-by="created_date" :sort-desc="true"
                            class="elevation-1" @click:row="openInfo" :item-class="setRowClass">
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-toolbar-title class="font-weight-bold">Expense Records</v-toolbar-title>
                                    <v-divider class="mx-4" inset vertical></v-divider>
                                    <v-select v-model="statusFilter" :items="statusSelected" @input="filterByStatus"
                                        style="max-width: 220px; margin-top: 30px; margin-right: 10px;"
                                        label="Filter Validated/Not Validated"></v-select>
                                    <v-text-field v-model="search" label="Search" solo-inverted
                                        prepend-icon="mdi-magnify" class="mr-3 mt-5"></v-text-field>
                                    <v-btn color="blue" dark class="mb-2 mr-2" @click="refreshItems(false)">
                                        <v-icon>mdi-refresh</v-icon>
                                    </v-btn>
                                    <v-btn color="success" dark class="mb-2 mr-2" @click="openDialog">
                                        <v-icon>mdi-plus-thick</v-icon>
                                    </v-btn>
                                </v-toolbar>
                            </template>
                            <template v-slot:item.amount="{ item }">
                                <!-- <span>$ {{ formatCurrency(item.expense_type.type[0].amount) }}</span> -->
                                <span>
                                    $ {{
                                        item.prorated
                                            ? formatCurrency(item.amount)
                                            : formatCurrency(item.expense_type.type[0].total)
                                    }}
                                </span>
                            </template>
                            <template v-slot:item.total="{ item }">
                                <!-- <span>$ {{ formatCurrency(item.expense_type.type[0].total) }}</span> -->
                                <span>
                                    $ {{
                                        item.prorated
                                            ? formatCurrency(item.amount)
                                            : formatCurrency(item.expense_type.type[0].total)
                                    }}
                                </span>
                            </template>
                            <template v-slot:item.discount="{ item }">
                                <span>$ {{ formatCurrency(item.expense_type.type[0].discount) }}</span>
                            </template>
                            <!-- Date Formatting -->
                            <template v-slot:item.created_date="{ item }">
                                <span>{{ formatDate(item.created_date) }}</span>
                            </template>
                            <!-- Concatenar Management User (name + last_name), manejando nulos -->
                            <template v-slot:item.management_name="{ item }">
                                <span>
                                    <!-- Verifica si es un department, si lo es, muestra el nombre del department -->
                                    <template v-if="item.management_name?.type === 'department'">
                                        {{ item.management_name?.name || 'N/A' }}
                                    </template>

                                    <template v-else-if="item.management_name?.type === 'employee'">
                                        {{ item.management_name?.name || 'N/A' }}
                                        {{ item.management_name?.last_name || '' }}
                                    </template>

                                    <!-- De lo contrario, muestra el nombre y apellido concatenado del assigned_user -->
                                    <template v-else-if="item.management_name?.assigned_user">
                                        {{ item.management_name?.assigned_user?.name?.name || 'N/A' }}
                                        {{ item.management_name?.assigned_user?.name?.last_name || '' }}
                                    </template>

                                    <template v-else>
                                        {{ item.management_name || 'N/A' }}
                                    </template>
                                </span>
                            </template>

                            <template v-slot:item.instructor="{ item }">
                                <span>
                                    {{ item.instructor || 'N/A' }}
                                </span>
                            </template>
                            <!-- Mostrar Vehicle Name, manejando nulos -->
                            <template v-slot:item.vehicle_name="{ item }">
                                <span>{{ item.vehicle_name || 'N/A' }}</span>
                            </template>
                            <template v-slot:item.expense_type="{ item }">
                                <!-- Mostrar ícono de archivo adjunto si files_names tiene elementos -->
                                <v-icon
                                    v-if="item.expense_type.type[0].files_names && item.expense_type.type[0].files_names.length > 0"
                                    class="d-flex justify-center align-center" color="purple">
                                    mdi-paperclip <!-- Este es el icono de archivo adjunto de ejemplo -->
                                </v-icon>
                            </template>
                            <template v-slot:item.prorated="{ item }">
                                <span>
                                    {{ item.prorated ? 'YES' : 'NO' }}
                                </span>
                            </template>
                        </v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-dialog v-if="dialogOpen" v-model="dialogOpen" max-width="1800">
                <v-card class="dialog-card" outlined>
                    <ExpenseRecordCard :suppliers="supliers" :EditedIndex="editedIndex" :Item="editedItem"
                        :Employee_number="employee_number" @sent-info="handleInfoFromChild" :index="editedIndex"
                        :key="dialogOpen.toString()" />
                </v-card>
            </v-dialog>
        </v-row>
        <div class="loader-container" v-if="showLoader">
            <div class="loader"></div>
        </div>
    </div>
</template>

<script>
import { bus } from '../../main.js';
import { getExpenseRecordsByEmployeeAndStatus, getExpenseRecordsByStatus } from '../../utils/Accounting/expense_record_CRUD'
import ExpenseRecordCard from './ExpenseRecordCard.vue';
import { get_role } from '../../utils/role'
import { DateTime } from 'luxon';
import _ from 'lodash';
import { fetchZohoVendors } from '../../utils/Accounting/ZOHO/vendors_CRUD'

export default {
    components: {
        ExpenseRecordCard
    },
    data: () => ({
        // SUPPLIER
        supliers: null,
        // LOADER
        showLoader: false,
        // STATUS FILTER
        statusFilter: 'Not Validated',
        statusSelected: ['Validated', 'Not Validated', 'All'],
        employee_number: '',
        headers: [
            { text: 'Expense Date', value: 'expense_date' },
            { text: 'UUID', value: 'uuid' },
            { text: 'Card Number', value: 'card_number' },
            { text: 'Prorated', value: 'prorated' },
            { text: 'Location', value: 'location' },
            { text: 'Supplier', value: 'expense_type.type[0].supplier.contact_name' },
            { text: 'Expense Type', value: 'expense_type.type[0].type.account_name' },
            { text: 'Management User', value: 'management_name' },
            { text: 'Vehicle Name', value: 'vehicle_name' },
            { text: 'Instructor', value: 'instructor' },
            { text: 'Department', value: 'department' },
            //{ text: 'Driver Name', value: 'driver_name' },
            /* { text: 'Created Date', value: 'created_date' }, */
            /* { text: 'Created By', value: 'created_by' }, */
            { text: 'Amount', value: 'amount' },
            { text: 'Discount', value: 'discount' },
            { text: 'Total', value: 'total' },
            { text: 'Attachment', value: 'expense_type' },
        ],
        dialogOpen: false,
        search: '',
        items: [],
        editedIndex: '',
        editedItem: {
            instructor: '',
            vehicle_name: '',
            location: '',
            department: '',
            expense_date: "",
            payment_method: "",
            card_number: {},
            card_account: {},
            expense_type: {
                type: [

                ]
            },
            ticket_name_s3: [],
            supplier: null,
            description: "",
            amount: null,
            management_name: null,
            driver_name: '',
        },
        defaultEditedItem: {
            instructor: '',
            vehicle_name: '',
            location: '',
            department: '',
            expense_date: "",
            payment_method: "",
            card_number: {},
            card_account: {},
            expense_type: {
                type: [

                ]
            },
            ticket_name_s3: [],
            supplier: null,
            description: "",
            amount: null,
            management_name: null,
            driver_name: '',
        },
    }),
    computed: {
        filteredItems() {
            if (!this.search.trim()) {
                return this.items;
            } else {
                return this.items.filter(item =>
                    item.uuid.toLowerCase().includes(this.search.toLowerCase())
                );
            }
        }
    },
    async mounted() {
        bus.$emit('login-success');
        bus.$emit('permissions');
        this.employee_number = get_role().employee_number;
        await this.fetchItems(false);


        this.showLoader = true;
        console.time('fetchZohoVendors');
        /* vehicles,  */
        const [zohoVendors] = await Promise.all([
            (async () => {
                const result = await fetchZohoVendors();
                console.timeEnd('fetchZohoVendors');
                return result;
            })(),
        ]);


        this.supliers = zohoVendors;
        this.showLoader = false;
    },
    watch: {
        dialogOpen(val) {
            if (val) {
                console.log("Card Opened....");
            } else {
                console.log("Card Closed....");
                this.editedItem = this.defaultEditedItem;
                this.editedIndex = '';
            }
        }
    },
    methods: {
        formatDate(date) {
            const chicagoTime = DateTime.fromISO(date, { zone: 'utc' }).setZone('America/Chicago');
            // Format the date in 'yyyy-MM-dd HH:mm:ss' format
            return chicagoTime.toFormat('yyyy-MM-dd HH:mm:ss');
        },
        setRowClass(item) {
            return item.status ? 'green-background' : 'red-background';
        },
        filterByStatus() {
            let status = false;
            if (this.statusFilter === 'Validated') {
                status = true
            } else if (this.statusFilter === 'Not Validated') {
                status = false
            } else {
                status = null
            }
            this.fetchItems(status)
        },
        formatCurrency(value) {
            const number = parseFloat(value);
            return !isNaN(number) ? number : "0.00";
        },/* .toFixed(2) */
        async handleInfoFromChild(info) {
            // Manejar la información recibida desde el hijo
            console.log('Información recibida desde el hijo:', info);
            this.dialogOpen = info; //Close dialog
            this.statusFilter = "Not Validated";
            await this.fetchItems(false); //Refresh employees table
        },
        async refreshItems(status) {
            await this.fetchItems(status);
            this.statusFilter = "Not Validated";
            console.log("this.statusFilter: ", this.statusFilter);
        },
        async fetchItems(status) {
            try {
                this.showLoader = true;
                console.log("status: ", status);
                console.log("this.employee_number: ", this.employee_number);
                //this.items = await getExpenseRecordsByEmployeeAndStatus(this.employee_number, status);
                this.items = await getExpenseRecordsByStatus(status);

                this.showLoader = false;
            } catch (error) {
                console.error("Error fetching: ", error);
            }
        },
        openDialog() {
            this.dialogOpen = true;
        },
        openInfo(item) {
            console.log("Openning info....", item);
            this.editedIndex = item.uuid;
            //this.editedItem = Object.assign({}, item);
            this.editedItem = _.cloneDeep(item);
            if (this.editedItem.management_name != null) {
                console.log("Assigning management_name...");
                this.editedItem.management_name = Object.assign({}, item.management_name);
            }
            console.log("this.editedItem: ", this.editedItem);
            this.dialogOpen = true;
        },
    }
}
</script>

<style scope>
/* Estilos para resaltar las filas según el status */
.green-background {
    background-color: rgba(0, 255, 0, 0.1);
}

.red-background {
    background-color: rgba(255, 0, 0, 0.1);
}

/* LOADER */
.loader-container {
    position: fixed;
    /* Para que el loader esté por encima de todo el contenido */
    top: 0;
    left: 0;
    width: 100%;
    /* Ocupa todo el ancho del viewport */
    height: 100%;
    /* Ocupa todo el alto del viewport */
    display: flex;
    justify-content: center;
    /* Centra horizontalmente */
    align-items: center;
    /* Centra verticalmente */
    background-color: rgba(255, 255, 255, 0.5);
    /* Fondo semitransparente */
    z-index: 9999;
    /* Coloca el loader por encima de todo el contenido */
}

.loader {
    width: 120px;
    height: 60px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    background: #fff;
    filter: blur(5px) contrast(10) hue-rotate(60deg);
    mix-blend-mode: darken;
}

.loader:before,
.loader:after {
    content: "";
    width: 40px;
    border-radius: 50%;
    background: #ff00ff;
    animation: l3 1s infinite alternate;
}

.loader:after {
    --s: -1;
}

@keyframes l3 {

    90%,
    100% {
        transform: translate(calc(var(--s, 1)*30px))
    }
}
</style>