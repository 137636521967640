<template>
  <div>
    <v-card :message="message" :newEmployee="newEmployee" :index="index">
      <v-card-title>
        <span class="text-h5">{{ formTitle }}</span>
        <!-- <span class="headline">Employee Information</span> -->
      </v-card-title>
      <v-card-text>
        <!-- Pestañas -->
        <v-tabs v-model="tab">
          <v-tab v-for="(item, index) in itemsTabs" :key="index">{{ item }}</v-tab>
        </v-tabs>
        <!-- Contenido de las pestañas -->
        <v-form ref='form'>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="(item, index) in itemsTabs" :key="index">
              <!-- Job Information -->
              <div v-if="index === 0" id="employeeForm">
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.employee_number" label="Employee number" readonly></v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-6"></v-divider>
                <h4>Choose the Company that applies to the applicant</h4>
                <v-divider class="mt-4 mb-6"></v-divider>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-select v-model="editedItem.company" :items="itemCompany" label="Company"
                      :readonly="pageRoute === '/employeesform'" :rules="computedRules" required></v-select>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-6"></v-divider>
                <h4>Personal Details</h4>
                <v-divider class="mt-4 mb-6"></v-divider>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.name" label="Name(s)" :readonly="pageRoute === '/employeesform'"
                      :rules="computedRules" required></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.last_name" label="Last name(s)"
                      :readonly="pageRoute === '/employeesform'" :rules="computedRules" required></v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-6"></v-divider>
                <h4>Contact</h4>
                <v-divider class="mt-4 mb-6"></v-divider>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.personal_email" label="Personal Email"
                      :readonly="pageRoute === '/employeesform'" :rules="computedRules" required></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.personal_phone" label="Personal Mobile"
                      :readonly="pageRoute === '/employeesform'" :rules="[...computedRules, phoneValidation]"
                      required></v-text-field><!-- @keydown="onlyNumbers" type="number" :rules="[rules.onlyNumbers]" -->
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-6"></v-divider>
                <h4>Employment</h4>
                <v-divider class="mt-4 mb-6"></v-divider>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.nova_email" label="Email (Verification Code)"
                      :readonly="pageRoute === '/employeesform'" :rules="computedRules" required></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-menu v-model="menuHireDate" :close-on-content-click="false" transition="scale-transition"
                      offset-y>
                      <template v-slot:activator="{ on }">
                        <v-text-field v-model="editedItem.hire_date" label="Position Start Date" readonly v-on="on"
                          :readonly="pageRoute === '/employeesform'" :rules="computedRules" required></v-text-field>
                      </template>
                      <v-date-picker v-model="editedItem.hire_date" @input="menuHireDate = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.job_change_reason" label="Job Change Reason"
                      :readonly="pageRoute === '/employeesform'"></v-text-field><!--  :rules="computedRules" required -->
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select v-model="editedItem.type_of_job" :items="itemTypeOfJob" label="Type of Job"
                      :readonly="pageRoute === '/employeesform'" :rules="computedRules" required></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select v-model="editedItem.worker_category" :items="itemWorkerCategory" label="Worker Category"
                      :readonly="pageRoute === '/employeesform'" :rules="computedRules" required></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select v-model="editedItem.position" :items="itemsPosition" label="Position"
                      :readonly="pageRoute === '/employeesform'" :rules="computedRules" required></v-select>
                  </v-col>
                  <v-col v-if="show_type_of_staff" cols="12" sm="6" md="4">
                    <v-select v-model="editedItem.type_of_staff" :items="itemsTypeOfStaff" label="Type of Staff"
                      :readonly="pageRoute === '/employeesform'" :rules="computedRules" required></v-select>
                  </v-col>
                  <v-col v-if="editedItem.position === 'Instructor'" cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.permit" label="Permit" :readonly="pageRoute === '/employeesform'"
                      :rules="computedRules" required></v-text-field>
                  </v-col>
                  <v-col v-if="editedItem.position === 'Instructor'" cols="12" sm="6" md="4">
                    <v-menu v-model="menu_permit_expiration" :close-on-content-click="false"
                      transition="scale-transition" offset-y>
                      <template v-slot:activator="{ on }">
                        <v-text-field v-model="editedItem.permit_expiration" label="Permit Expiration" v-on="on"
                          :rules="permitRules" required></v-text-field>
                      </template>
                      <v-date-picker v-model="editedItem.permit_expiration"
                        @input="menu_permit_expiration = false"></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col v-if="editedItem.position === 'Instructor' || editedItem.position === 'Operator'" cols="12"
                    sm="6" md="4">
                    <v-select v-model="editedItem.type_of_position" :items="itemstype_of_position"
                      label="Type of Position" :readonly="pageRoute === '/employeesform'" :rules="positionRules"
                      required></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select v-model="editedItem.permissions" :items="itemsPermissions" label="Permissions"
                      :readonly="pageRoute === '/employeesform'" :rules="computedRules" required></v-select><!-- :error-messages="pageRoute === '/employees' ? validateField('permissions') : []"
                    :required="pageRoute === '/employees'"
                    @change="pageRoute === '/employees' ? $v.editedItem.permissions.$touch() : null"
                    @blur="pageRoute === '/employees' ? $v.editedItem.permissions.$touch() : null" -->
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select v-model="editedItem.department" :items="itemsDepartment" label="Department"
                      :readonly="pageRoute === '/employeesform'" :rules="computedRules" required></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select v-model="editedItem.department2" :items="itemsDepartment" label="Department 2"
                      :readonly="pageRoute === '/employeesform'" clearable></v-select>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-6"></v-divider>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <!-- <v-text-field v-model="editedItem.report" label="Report to"
                      :readonly="pageRoute === '/employeesform'" :rules="computedRules" required></v-text-field> -->
                    <v-combobox v-model="reportToSelected" :items="reportToItem" :item-text="concatName"
                      label="Report To" return-object
                      :readonly="pageRoute === '/employeesform'"></v-combobox><!-- :rules="computedRules" required -->
                  </v-col>
                  <v-col cols="6">
                    <v-btn :disabled="!isFormComplete" @click="addItem" :class="buttonClasses" icon>
                      <v-icon class="white--text icon-thick">mdi-plus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <template>
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left" style="color: #000; font-weight: bold !important; font-size: 14px;">
                                Name(s)
                              </th>
                              <th class="text-left" style="color: #000; font-weight: bold !important; font-size: 14px;">
                                Last Name(s)
                              </th>
                              <th class="text-left" style="color: #000; font-weight: bold !important; font-size: 14px;">
                                Action
                              </th>
                              <!-- <th class="text-left" style="color: #000; font-weight: bold !important; font-size: 14px;">
                                Price
                              </th>
                              <th class="text-left" style="color: #000; font-weight: bold !important; font-size: 14px;">
                                Actions
                              </th> -->
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(item, index) in editedItem.report" :key="index">
                              <td style="white-space: normal; word-wrap: break-word;">{{
                                item.name }}</td>
                              <td style="white-space: normal; word-wrap: break-word;">{{
                                item.last_name }}</td>
                              <!-- <td>$ {{ parseFloat(item.price).toFixed(2) }}</td> -->
                              <td>
                                <v-btn icon @click="removeDriver(index)">
                                  <v-icon color="red">mdi-delete</v-icon>
                                </v-btn>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </template>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-6"></v-divider>
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <v-textarea v-model="editedItem.activity_details" label="Activity Details" outlined rows="5"
                      auto-grow :readonly="pageRoute === '/employeesform'"></v-textarea>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-6"></v-divider>
                <h4>Work Schedule</h4>
                <v-divider class="mt-4 mb-6"></v-divider>
                <v-col>
                  <v-row v-for="(day, index) in editedItem.days" :key="index" class="custom-row">
                    <v-col cols="4">
                      <v-checkbox v-model="day.checked" :label="day.day_of_week" @change="resetTimes(index)"
                        :readonly="pageRoute === '/employeesform'"></v-checkbox>
                    </v-col>
                    <v-col cols="4">
                      <v-menu v-model="day.startTimeMenu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y :disabled="!day.checked">
                        <template v-slot:activator="{ on }">
                          <v-text-field v-model="day.start_time" label="Start Time"
                            append-icon="mdi-clock-time-four-outline" v-on="on"
                            :readonly="pageRoute === '/employeesform'"></v-text-field><!-- :disabled="pageRoute === '/employeesform'" -->
                        </template>
                        <v-time-picker v-if="day.startTimeMenu" v-model="day.start_time" format="24hr"
                          @input="day.startTimeMenu = false" :disabled="pageRoute === '/employeesform'"></v-time-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="4">
                      <v-menu v-model="day.endTimeMenu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y :disabled="!day.checked">
                        <template v-slot:activator="{ on }">
                          <v-text-field v-model="day.end_time" label="End Time"
                            append-icon="mdi-clock-time-four-outline" v-on="on"
                            :readonly="pageRoute === '/employeesform'"></v-text-field>
                        </template>
                        <v-time-picker v-if="day.endTimeMenu" v-model="day.end_time" format="24hr"
                          @input="day.endTimeMenu = false" :disabled="pageRoute === '/employeesform'"></v-time-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-combobox v-model="locationSelected" :items="itemLocations" item-text="location"
                      label="Location Assigned" return-object :rules="computedRules" required
                      :readonly="pageRoute === '/employeesform'"></v-combobox>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <v-textarea v-model="editedItem.observations" label="Observations" outlined rows="5" auto-grow
                      :readonly="pageRoute === '/employeesform'"></v-textarea>
                  </v-col>
                </v-row>

              </div>
              <!-- Employee Information -->
              <div v-if="index === 3">
                <div id="personalInformationForm">
                  <v-divider class="mt-4 mb-6"></v-divider>
                  <h4>Personal Information</h4>
                  <v-divider class="mt-4 mb-6"></v-divider>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-menu v-model="menuBirthdate" :close-on-content-click="false" transition="scale-transition"
                        offset-y>
                        <template v-slot:activator="{ on }">
                          <v-text-field v-model="editedItem.birthdate" label="Birthdate" v-on="on"
                            :rules="employeeRules" required></v-text-field>
                        </template>
                        <v-date-picker v-model="editedItem.birthdate" @input="menuBirthdate = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select v-model="editedItem.gender" :items="itemsGender" label="Gender" :rules="employeeRules"
                        required></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select v-model="editedItem.id_type" :items="itemIdType" label="Id Type" :rules="employeeRules"
                        required></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.id_number" label="Id Number" :rules="employeeRules"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.country" label="Country" :rules="employeeRules"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.state" label="State/Province" :rules="employeeRules"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.city" label="City" :rules="employeeRules"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.address" label="Address" :rules="employeeRules"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.postal_code" label="Postal Code" :rules="employeeRules"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select v-model="editedItem.marital_status" :items="itemMaritalStatus" label="Marital Status"
                        :rules="employeeRules" required></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-menu v-model="menuSince" :close-on-content-click="false" transition="scale-transition"
                        offset-y>
                        <template v-slot:activator="{ on }">
                          <v-text-field v-model="editedItem.since" label="Since" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="editedItem.since" @input="menuSince = false"></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select v-model="editedItem.ethnicity" :items="itemEthnicity" label="Ethnicity"
                        :rules="employeeRules" required></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select v-model="raceSelected" :items="itemRace" label="Race" :rules="employeeRules"
                        required></v-select>
                    </v-col>
                    <v-col v-if="raceSelected === 'Other'" cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.race" label="Other Race" :rules="employeeRules"
                        required></v-text-field>
                    </v-col>


                    <v-col cols="12" sm="6" md="4">
                      <v-select v-model="editedItem.medicare" :items="itemDecision" label="Medicare"
                        :rules="employeeRules" required></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select v-model="editedItem.medicaid" :items="itemDecision" label="Medicaid"
                        :rules="employeeRules" required></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select v-model="editedItem.tabacco_user" :items="itemDecision" label="Tabacco User"
                        :rules="employeeRules" required></v-select>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-select v-model="editedItem.veteran" :items="itemDecision" label="Veteran"
                        :rules="employeeRules" required></v-select>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-4 mb-6"></v-divider>
                  <h4>Emergency Contact</h4>
                  <v-divider class="mt-4 mb-6"></v-divider>
                  <v-row>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.name_emergency" label="Name(s)" :rules="employeeRules"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.last_name_emergency" label="Last name(s)" :rules="employeeRules"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.phone_emergency" label="Phone number" @keydown="onlyNumbers"
                        :rules="employeeRules" required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.address_emergency" label="Address" :rules="employeeRules"
                        required></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                      <v-text-field v-model="editedItem.relationship_emergency" label="Relationship of Contact"
                        :rules="employeeRules" required></v-text-field>
                    </v-col>
                  </v-row>
                  <div id="signature_canvas">
                    <v-divider class="mt-4 mb-6"></v-divider>
                    <h4>Signature</h4>
                    <v-divider class="mt-4 mb-6"></v-divider>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <div class="signature-pad-container">
                          <canvas class="signature-pad-canvas" :readonly="formStatus"></canvas>
                        </div>
                      </v-col>
                    </v-row>
                  </div>

                </div>
                <v-btn v-if="pageRoute === '/employeesform' && formStatus === false" color="blue" @click="clearPad" dark
                  class="mt-5">Clear</v-btn>
              </div>
              <!-- Polícies -->
              <div v-if="index === 5" id="docsForm">
                <v-divider class="mt-4 mb-6"></v-divider>
                <h3>Policies</h3>
                <v-divider class="mt-4 mb-6"></v-divider>
                <v-row>
                  <v-col cols="6" sm="6" md="6">
                    <!-- Employee Handbook File -->
                    <div class="d-flex align-items-end">
                      <h4 class="m-0">Employee Handbook File</h4>
                      <v-btn icon
                        :class="{ 'green--text': editedItem.employee_handbookurl === true, 'red--text': !editedItem.employee_handbookurl }"
                        @click="openPolicyModal('Employee Handbook', 'employee_handbook.pdf', editedItem.employee_handbook_File)"
                        class="ml-2 p-0">
                        <v-icon>mdi-file</v-icon>
                      </v-btn>
                    </div>
                    <!-- Acknowledgement of handbook File -->
                    <div class="d-flex align-items-end">
                      <h4 class="m-0">Acknowledgement of handbook File</h4>
                      <v-btn icon
                        :class="{ 'green--text': editedItem.acknowledgement_of_handbookurl === true, 'red--text': !editedItem.acknowledgement_of_handbookurl }"
                        @click="openPolicyModal('Acknowledgement of handbook', 'acknowledgement_of_handbook.pdf', editedItem.acknowledgement_of_handbookurl)"
                        class="ml-2 p-0">
                        <v-icon>mdi-file</v-icon>
                      </v-btn>
                    </div>
                    <!-- Code of discipline policy File -->
                    <div class="d-flex align-items-end">
                      <h4 class="m-0">Code of discipline policy File</h4>
                      <v-btn icon
                        :class="{ 'green--text': editedItem.code_of_discipline_policyurl === true, 'red--text': !editedItem.code_of_discipline_policyurl }"
                        @click="openPolicyModal('Code of discipline', 'code_of_discipline_policy.pdf', editedItem.code_of_discipline_policyurl)"
                        class="ml-2 p-0">
                        <v-icon>mdi-file</v-icon>
                      </v-btn>
                    </div>
                    <!-- Dress code policy video File -->
                    <div class="d-flex align-items-end">
                      <h4 class="m-0">Dress code video File</h4>
                      <v-btn icon
                        :class="{ 'green--text': editedItem.dress_code_policy_videourl === true, 'red--text': !editedItem.dress_code_policy_videourl }"
                        @click="openPolicyModal('Dress code policy video File', 'dress_code_policy_video.mp4', editedItem.dress_code_policy_videourl)"
                        class="ml-2 p-0">
                        <v-icon>mdi-video</v-icon>
                      </v-btn>
                    </div>
                    <!-- Dress code policy File -->
                    <div class="d-flex align-items-end">
                      <h4 class="m-0">Dress code policy File</h4>
                      <v-btn icon
                        :class="{ 'green--text': editedItem.dress_code_policyurl === true, 'red--text': !editedItem.dress_code_policyurl }"
                        @click="openPolicyModal('Dress Code', 'dress_code_policy.pdf', editedItem.dress_code_policyurl)"
                        class="ml-2 p-0">
                        <v-icon>mdi-file</v-icon>
                      </v-btn>
                    </div>
                    <!-- Sales enrollment policy File -->
                    <div v-if="editedItem.type_of_job === 'BTW Instructor' ||
                      editedItem.type_of_job === 'CR Teacher' ||
                      editedItem.type_of_job === 'BTW Instructor & CR Teacher' ||
                      editedItem.type_of_job === 'Customer Service & Sales Representative - Remote' ||
                      editedItem.type_of_job === 'Customer Service & Sales Representative - Office Staff' ||
                      editedItem.type_of_job === 'Field Maintenance Operator' ||
                      editedItem.type_of_job === 'Admin Staff - Office Staff'" class="d-flex align-items-end">
                      <h4 class="m-0">Sales enrollment policy File</h4>
                      <v-btn icon
                        :class="{ 'green--text': editedItem.sales_enrollment_policyurl === true, 'red--text': !editedItem.sales_enrollment_policyurl }"
                        @click="openPolicyModal('Sales enrollment', 'sales_enrollment_policy.pdf', editedItem.sales_enrollment_policyurl)"
                        class="ml-2 p-0">
                        <v-icon>mdi-file</v-icon>
                      </v-btn>
                    </div>
                    <!-- Attendance policy File -->
                    <div class="d-flex align-items-end">
                      <h4 class="m-0">Attendance policy File</h4>
                      <v-btn icon
                        :class="{ 'green--text': editedItem.attendance_policyurl === true, 'red--text': !editedItem.attendance_policyurl }"
                        @click="openPolicyModal('Attendance', 'attendance_policy.pdf', editedItem.attendance_policyurl)"
                        class="ml-2 p-0">
                        <v-icon>mdi-file</v-icon>
                      </v-btn>
                    </div>
                    <!-- Advances and loan policy File -->
                    <div class="d-flex align-items-end">
                      <h4 class="m-0">Advances and loan policy File</h4>
                      <v-btn icon
                        :class="{ 'green--text': editedItem.advances_and_loan_policyurl === true, 'red--text': !editedItem.advances_and_loan_policyurl }"
                        @click="openPolicyModal('Advances and loan', 'advances_and_loan_policy.pdf', editedItem.advances_and_loan_policyurl)"
                        class="ml-2 p-0">
                        <v-icon>mdi-file</v-icon>
                      </v-btn>
                    </div>
                    <!-- No show and late cancellation File -->
                    <div v-if="editedItem.type_of_job === 'BTW Instructor' ||
                      editedItem.type_of_job === 'CR Teacher' ||
                      editedItem.type_of_job === 'BTW Instructor & CR Teacher' ||
                      editedItem.type_of_job === 'Field Maintenance Operator' ||
                      editedItem.type_of_job === 'Customer Service & Sales Representative - Remote' ||
                      editedItem.type_of_job === 'Admin Staff - Remote' ||
                      editedItem.type_of_job === 'Admin Staff - Office Staff'" class="d-flex align-items-end">
                      <h4 class="m-0">No show and late cancellation File</h4>
                      <v-btn icon
                        :class="{ 'green--text': editedItem.no_show_and_late_cancellationurl === true, 'red--text': !editedItem.no_show_and_late_cancellationurl }"
                        @click="openPolicyModal('No show and late cancellation', 'no_show_and_late_cancellation.pdf', editedItem.no_show_and_late_cancellationurl)"
                        class="ml-2 p-0">
                        <v-icon>mdi-file</v-icon>
                      </v-btn>
                    </div>
                    <!-- Sexual harassment training video File -->
                    <div class="d-flex align-items-end">
                      <h4 class="m-0">Sexual harassment training video File</h4>
                      <v-btn icon
                        :class="{ 'green--text': editedItem.sexual_harassment_training_videourl === true, 'red--text': !editedItem.sexual_harassment_training_videourl }"
                        @click="openPolicyModal('Sexual harassment training video File', 'sexual_harassment_training_video.mp4', editedItem.sexual_harassment_training_acknowledgement_videourl)"
                        class="ml-2 p-0">
                        <v-icon>mdi-video</v-icon>
                      </v-btn>
                    </div>
                    <!-- Sexual harassment training acknowledgement File -->
                    <div class="d-flex align-items-end">
                      <h4 class="m-0">Sexual harassment training acknowledgement File</h4>
                      <v-btn icon
                        :class="{ 'green--text': editedItem.sexual_harassment_training_acknowledgementurl === true, 'red--text': !editedItem.sexual_harassment_training_acknowledgementurl }"
                        @click="openPolicyModal('Sexual harassment training acknowledgement', 'sexual_harassment_training_acknowledgement.pdf', editedItem.sexual_harassment_training_acknowledgementurl)"
                        class="ml-2 p-0">
                        <v-icon>mdi-file</v-icon>
                      </v-btn>
                    </div>
                    <!-- Timekeeping policy Video -->
                    <div
                      v-if="editedItem.type_of_job != 'BTW Instructor' || editedItem.type_of_job != 'CR Teacher' || editedItem.type_of_job != 'BTW Instructor & CR Teacher'"
                      class="d-flex align-items-end">
                      <h4 class="m-0">Time Clock Wizard video file</h4>
                      <v-btn icon
                        :class="{ 'green--text': editedItem.time_clock_wizard_videourl === true, 'red--text': !editedItem.time_clock_wizard_videourl }"
                        @click="openPolicyModal('Time Clock Wizard video file', 'time_clock_wizard_video.mp4', editedItem.time_clock_wizard_videourl)"
                        class="ml-2 p-0">
                        <v-icon>mdi-video</v-icon>
                      </v-btn>
                    </div>
                    <!-- Timekeeping policy File -->
                    <div
                      v-if="editedItem.type_of_job != 'BTW Instructor' || editedItem.type_of_job != 'CR Teacher' || editedItem.type_of_job != 'BTW Instructor & CR Teacher'"
                      class="d-flex align-items-end">
                      <h4 class="m-0">Timekeeping policy File</h4>
                      <v-btn icon
                        :class="{ 'green--text': editedItem.timekeeping_policyurl === true, 'red--text': !editedItem.timekeeping_policyurl }"
                        @click="openPolicyModal('Timekeeping', 'timekeeping_policy.pdf', editedItem.timekeeping_policyurl)"
                        class="ml-2 p-0">
                        <v-icon>mdi-file</v-icon>
                      </v-btn>
                    </div>
                    <!-- Remote staff working policy File -->
                    <div
                      v-if="editedItem.type_of_job === 'Customer Service & Sales Representative - Remote' || editedItem.type_of_job === 'Admin Staff - Remote'"
                      class="d-flex align-items-end">
                      <h4 class="m-0">Remote staff working policy File</h4>
                      <v-btn icon
                        :class="{ 'green--text': editedItem.remote_staff_working_policyurl === true, 'red--text': !editedItem.remote_staff_working_policyurl }"
                        @click="openPolicyModal('Remote staff working', 'remote_staff_working_policy.pdf', editedItem.remote_staff_working_policyurl)"
                        class="ml-2 p-0">
                        <v-icon>mdi-file</v-icon>
                      </v-btn>
                    </div>
                    <!-- Cash handling policy File -->
                    <div
                      v-if="editedItem.type_of_job === 'Customer Service & Sales Representative - Office Staff' || editedItem.type_of_job === 'Admin Staff - Office Staff'"
                      class="d-flex align-items-end">
                      <h4 class="m-0">Cash handling policy File</h4>
                      <v-btn icon
                        :class="{ 'green--text': editedItem.cash_handling_policyurl === true, 'red--text': !editedItem.cash_handling_policyurl }"
                        @click="openPolicyModal('Cash handling', 'cash_handling_policy.pdf', editedItem.cash_handling_policyurl)"
                        class="ml-2 p-0">
                        <v-icon>mdi-file</v-icon>
                      </v-btn>
                    </div>
                    <!-- Acknowledgement Invoice Submission -->
                    <div v-if="editedItem.company === 'V-Outsourcing'" class="d-flex align-items-end">
                      <!-- || editedItem.company === 'V-Outsourcing/Gusto' -->
                      <h4 class="m-0">Acknowledgement Invoice Submission</h4>
                      <v-btn icon
                        :class="{ 'green--text': editedItem.acknowledgement_invoice_submissionurl === true, 'red--text': !editedItem.acknowledgement_invoice_submissionurl }"
                        @click="openPolicyModal('Acknowledgement Invoice Submission', 'acknowledgement_invoice_submission.pdf', editedItem.acknowledgement_invoice_submissionurl)"
                        class="ml-2 p-0">
                        <v-icon>mdi-file</v-icon>
                      </v-btn>
                    </div>
                    <!-- Vehicle Use And Care -->
                    <div v-if="editedItem.assigned_vehicle === 'Yes'" class="d-flex align-items-end">
                      <h4 class="m-0">Vehicle Use And Care</h4>
                      <v-btn icon
                        :class="{ 'green--text': editedItem.vehicle_use_and_careurl === true, 'red--text': !editedItem.vehicle_use_and_careurl }"
                        @click="openPolicyModal('Vehicle Use And Care', 'vehicle_use_and_care.pdf', editedItem.vehicle_use_and_careurl)"
                        class="ml-2 p-0">
                        <v-icon>mdi-file</v-icon>
                      </v-btn>
                    </div>
                    <policy-modal :policyModalOpen.sync="policyModalOpen" :policyTitle="policyModalTitle"
                      :policyFileUrl="policyFileUrl" :formStatus="formStatus" :policy="policy"
                      @accepted_policy="accepted_policy" :key="policyModalOpen.toString()"
                      :digitalSignature="signatureImage" :statusPolicy="statusPolicy"
                      :s3_urlImage="signatureUrl"></policy-modal>
                  </v-col>
                </v-row>
              </div>
              <!-- Documents -->
              <div v-if="index === 4" id="docsForm">
                <v-divider class="mt-4 mb-6"></v-divider>
                <h4>Job Offer Document</h4>
                <v-divider class="mt-4 mb-6"></v-divider>
                <!-- get_job_offer -->
                <!-- <v-btn icon
                  :class="{ 'green--text': editedItem.job_offerurl === true, 'red--text': editedItem.job_offerurl != true }">
                  <v-icon @click="openModal('Job Offer', 'job_offer.pdf', jobOfferFile)">mdi-file</v-icon>
                </v-btn> -->
                <v-btn icon :class="{ 'purple--text': editedItem.form_status === true }">
                  <v-icon @click="get_job_offer">mdi-file</v-icon>
                </v-btn>
                <v-divider class="mt-4 mb-6"></v-divider>
                <h4>Documents</h4>
                <v-divider class="mt-4 mb-6"></v-divider>
                <v-row>
                  <v-col cols="6" sm="4" md="6">
                    <!-- recentPhotoFile -->
                    <v-row>
                      <v-col cols="12">
                        <div class="d-flex align-center">
                          <v-file-input label="Recent photo file" v-model="recentPhotoFile"
                            @change="onFileSelected('recentPhotoFile', 'recentPhoto')" accept="/*"
                            :disabled="editedItem.form_status && pageRoute === '/employeesform'">
                          </v-file-input>
                          <v-btn icon
                            :class="{ 'green--text': editedItem.recentphotourl === true, 'red--text': editedItem.recentphotourl != true }">
                            <v-icon
                              @click="openModal('Recent photo', 'recentphoto.pdf', recentPhotoFile)">mdi-file</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- bachellorDegreeFile -->
                    <v-row>
                      <v-col>
                        <div class="d-flex align-center">
                          <v-file-input label="Bachelor degree file" v-model="bachellorDegreeFile"
                            @change="onFileSelected('bachellorDegreeFile', 'bachellorDegree')" accept="application/pdf"
                            :disabled="editedItem.form_status && pageRoute === '/employeesform'"></v-file-input>
                          <v-btn icon
                            :class="{ 'green--text': editedItem.bachellordegreeurl === true, 'red--text': editedItem.bachellordegreeurl != true }">
                            <v-icon
                              @click="openModal('Bachelor degree', 'bachellordegree.pdf', bachellorDegreeFile)">mdi-file</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- resumeFile -->
                    <v-row>
                      <v-col>
                        <div class="d-flex align-center">
                          <v-file-input label="Resume file" v-model="resumeFile"
                            @change="onFileSelected('resumeFile', 'resume')" accept="application/pdf"
                            :disabled="editedItem.form_status && pageRoute === '/employeesform'"></v-file-input>
                          <v-btn icon
                            :class="{ 'green--text': editedItem.resumeurl === true, 'red--text': editedItem.resumeurl != true }">
                            <v-icon @click="openModal('Resume', 'resume.pdf', resumeFile)">mdi-file</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- idFile -->
                    <v-row>
                      <v-col>
                        <div class="d-flex align-center">
                          <v-file-input label="ID file" v-model="idFile" @change="onFileSelected('idFile', 'id')"
                            accept="application/pdf"
                            :disabled="editedItem.form_status && pageRoute === '/employeesform'"></v-file-input>
                          <v-btn icon
                            :class="{ 'green--text': editedItem.idurl === true, 'red--text': editedItem.idurl != true }">
                            <v-icon @click="openModal('ID', 'id.pdf', idFile)">mdi-file</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- passportFile -->
                    <v-row>
                      <v-col>
                        <div class="d-flex align-center">
                          <v-file-input label="Passport file" v-model="passportFile"
                            @change="onFileSelected('passportFile', 'passport')" accept="application/pdf"
                            :disabled="editedItem.form_status && pageRoute === '/employeesform'"></v-file-input>
                          <v-btn icon
                            :class="{ 'green--text': editedItem.passporturl === true, 'red--text': editedItem.passporturl != true }">
                            <v-icon @click="openModal('Passport', 'passport.pdf', passportFile)">mdi-file</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="6" sm="4" md="6">
                    <!-- RUC/RIMPE/SAT file -->
                    <v-row
                      v-if="editedItem.company === 'V-Outsourcing' && editedItem.worker_category === 'Professional Service'">
                      <v-col cols="12">
                        <div class="d-flex align-center">
                          <v-file-input label="RUC/RIMPE/SAT file" v-model="tax_certificate_File"
                            @change="onFileSelected('tax_certificate_File', 'tax_certificate')" accept="application/pdf"
                            :disabled="editedItem.form_status && pageRoute === '/employeesform'">
                          </v-file-input>
                          <v-btn icon
                            :class="{ 'green--text': editedItem.tax_certificateurl === true, 'red--text': editedItem.tax_certificateurl != true }">
                            <v-icon
                              @click="openModal('Tax certificate', 'tax_certificate.pdf', tax_certificate_File)">mdi-file</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- Invoice example file -->
                    <v-row
                      v-if="editedItem.company === 'V-Outsourcing' && editedItem.worker_category === 'Professional Service'">
                      <v-col cols="12">
                        <div class="d-flex align-center">
                          <v-file-input label="Invoice example file" v-model="invoice_example_File"
                            @change="onFileSelected('invoice_example_File', 'invoice_example')" accept="application/pdf"
                            :disabled="editedItem.form_status && pageRoute === '/employeesform'">
                          </v-file-input>
                          <v-btn icon
                            :class="{ 'green--text': editedItem.invoice_exampleurl === true, 'red--text': editedItem.invoice_exampleurl != true }">
                            <v-icon
                              @click="openModal('Invoice example', 'invoice_example.pdf', invoice_example_File)">mdi-file</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- Bank certificate file -->
                    <v-row
                      v-if="editedItem.company === 'V-Outsourcing' && editedItem.worker_category === 'Professional Service'">
                      <v-col cols="12">
                        <div class="d-flex align-center">
                          <v-file-input label="Bank certificate file" v-model="bank_certificate_File"
                            @change="onFileSelected('bank_certificate_File', 'bank_certificate')"
                            accept="application/pdf"
                            :disabled="editedItem.form_status && pageRoute === '/employeesform'">
                          </v-file-input>
                          <v-btn icon
                            :class="{ 'green--text': editedItem.bank_certificateurl === true, 'red--text': editedItem.bank_certificateurl != true }">
                            <v-icon
                              @click="openModal('Bank certificate', 'bank_certificate.pdf', bank_certificate_File)">mdi-file</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- Work authorization file -->
                    <v-row
                      v-if="((editedItem.company === 'Nova Driving School' || editedItem.company === 'V-Outsourcing/Gusto')) && editedItem.worker_category === 'Employee'"><!-- v-if="editedItem.company === 'Nova Driving School'" -->
                      <v-col cols="12">
                        <div class="d-flex align-center">
                          <v-file-input label="Work authorization file" v-model="work_authorization_File"
                            @change="onFileSelected('work_authorization_File', 'work_authorization')"
                            accept="application/pdf"
                            :disabled="editedItem.form_status && pageRoute === '/employeesform'">
                          </v-file-input>
                          <v-btn icon
                            :class="{ 'green--text': editedItem.work_authorizationurl === true, 'red--text': editedItem.work_authorizationurl != true }">
                            <v-icon
                              @click="openModal('Work authorization', 'work_authorization.pdf', work_authorization_File)">mdi-file</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- W4 file -->
                    <v-row
                      v-if="(editedItem.company === 'Nova Driving School' || editedItem.company === 'V-Outsourcing/Gusto') && editedItem.worker_category === 'Employee'">
                      <v-col cols="12"><!-- v-if="editedItem.company === 'Nova Driving School'" -->
                        <div class="d-flex align-center">
                          <v-file-input label="W4 file" v-model="w4_File" @change="onFileSelected('w4_File', 'w4')"
                            accept="application/pdf"
                            :disabled="editedItem.form_status && pageRoute === '/employeesform'">
                          </v-file-input>
                          <v-btn icon
                            :class="{ 'green--text': editedItem.w4url === true, 'red--text': editedItem.w4url != true }">
                            <v-icon @click="openModal('W4', 'w4.pdf', w4_File)">mdi-file</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- i9 file -->
                    <v-row
                      v-if="(editedItem.company === 'Nova Driving School' || editedItem.company === 'V-Outsourcing/Gusto') && editedItem.worker_category === 'Employee'">
                      <v-col cols="12"><!-- v-if="editedItem.company === 'Nova Driving School'" -->
                        <div class="d-flex align-center">
                          <v-file-input label="i9 file" v-model="i9_File" @change="onFileSelected('i9_File', 'i9')"
                            accept="application/pdf"
                            :disabled="editedItem.form_status && pageRoute === '/employeesform'">
                          </v-file-input>
                          <v-btn icon
                            :class="{ 'green--text': editedItem.i9url === true, 'red--text': editedItem.i9url != true }">
                            <v-icon @click="openModal('i9', 'i9.pdf', i9_File)">mdi-file</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- iL file -->
                    <v-row
                      v-if="(editedItem.company === 'Nova Driving School' || editedItem.company === 'V-Outsourcing/Gusto') && editedItem.worker_category === 'Employee'">
                      <v-col cols="12">
                        <div class="d-flex align-center">
                          <v-file-input label="il file" v-model="il_File" @change="onFileSelected('il_File', 'il')"
                            accept="application/pdf"
                            :disabled="editedItem.form_status && pageRoute === '/employeesform'">
                          </v-file-input>
                          <v-btn icon
                            :class="{ 'green--text': editedItem.ilurl === true, 'red--text': editedItem.ilurl != true }">
                            <v-icon @click="openModal('il', 'il.pdf', il_File)">mdi-file</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- W9 file -->
                    <v-row
                      v-if="editedItem.worker_category === 'Independent Contract' && editedItem.company === 'V-Outsourcing/Gusto'">
                      <v-col cols="12">
                        <div class="d-flex align-center">
                          <v-file-input label="W9 file" v-model="w9_File" @change="onFileSelected('w9_File', 'w9')"
                            accept="application/pdf"
                            :disabled="editedItem.form_status && pageRoute === '/employeesform'">
                          </v-file-input>
                          <v-btn icon
                            :class="{ 'green--text': editedItem.w9url === true, 'red--text': editedItem.w9url != true }">
                            <v-icon @click="openModal('W9', 'w9.pdf', w9_File)">mdi-file</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                    <!-- Social Security Number file -->
                    <v-row
                      v-if="(editedItem.company === 'Nova Driving School' || editedItem.company === 'V-Outsourcing/Gusto') && editedItem.worker_category === 'Employee'">
                      <v-col cols="12">
                        <div class="d-flex align-center">
                          <v-file-input label="Social Security Number file" v-model="ssn_File"
                            @change="onFileSelected('ssn_File', 'ssn')" accept="application/pdf"
                            :disabled="editedItem.form_status && pageRoute === '/employeesform'">
                          </v-file-input>
                          <v-btn icon
                            :class="{ 'green--text': editedItem.ssnurl === true, 'red--text': editedItem.ssnurl != true }">
                            <v-icon @click="openModal('SSN', 'ssn.pdf', ssn_File)">mdi-file</v-icon>
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </div>
              <!-- Other Data -->
              <div v-if="index === 2" id="otherDataForm">
                <!-- Fleet - Vehicle -->
                <v-divider class="mt-4 mb-6"></v-divider>
                <h4>Fleet - Vehicle</h4>
                <v-divider class="mt-4 mb-6"></v-divider>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-select v-model="editedItem.assigned_vehicle" :items="itemDecision" label="Assigned Vehicle"
                      :rules="computedRules" required :readonly="pageRoute === '/employeesform'"></v-select>
                  </v-col>
                  <!-- <v-col v-if="editedItem.assigned_vehicle === 'Yes'" cols="12" sm="6" md="4">
                    <v-combobox v-model="carSelected" :items="itemVehicles" item-text="name" label="Vehicle"
                      :rules="[rules.required]" required
                      return-object></v-combobox>
                  </v-col> -->
                </v-row>
                <v-divider class="mt-4 mb-6"></v-divider>
                <h4>8x8</h4>
                <v-divider class="mt-4 mb-6"></v-divider>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.phone_8x8" label="Phone 8x8"
                      :readonly="pageRoute === '/employeesform'" :rules="computedRules" required></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.extension_phone_8x8" label="Extension Phone 8x8"
                      :readonly="pageRoute === '/employeesform'" :rules="computedRules" required></v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-6"></v-divider>
                <h4>Danubenet</h4>
                <v-divider class="mt-4 mb-6"></v-divider>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.danubanet_name_1" label="Danubenet name 1"
                      :readonly="pageRoute === '/employeesform'" :rules="computedRules" required></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.danubanet_name_2" label="Danubenet name 2"
                      :readonly="pageRoute === '/employeesform'" :rules="computedRules" required></v-text-field>
                  </v-col>
                </v-row>
              </div>
              <!-- Accounting -->
              <div v-if="index === 1" id="accountingForm">
                <v-divider class="mt-4 mb-6"></v-divider>
                <h4>Regular Pay</h4>
                <v-divider class="mt-4 mb-6"></v-divider>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-select v-model="editedItem.type_of_income" :items="itemRate" label="Type of Income"
                      :readonly="pageRoute === '/employeesform'" :rules="computedRules" required></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select v-model="editedItem.pay_frequency" :items="itemPayFrequency" label="Pay Frequency"
                      :readonly="pageRoute === '/employeesform'" :rules="computedRules" required></v-select>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-6"></v-divider>
                <h4>Additional Hourly Rates Detail</h4>
                <v-divider class="mt-4 mb-6"></v-divider>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.assignment_rate" label="$ Instructor CS Rate"
                      :readonly="pageRoute === '/employeesform'" type="number" step="0.1" :rules="computedRules"
                      required></v-text-field>
                  </v-col>
                  <!-- Receives Commissions -->
                  <v-col cols="12" sm="6" md="4">
                    <v-select v-model="editedItem.receives_comissions" :items="itemDecision"
                      label="Receives Commissions" :readonly="pageRoute === '/employeesform'" :rules="computedRules"
                      required></v-select>
                  </v-col>
                  <!-- Commission % -->
                  <v-col v-if="editedItem.receives_comissions === 'Yes'" cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.commissions" label="Commission %"
                      @keydown="onlyNumbers"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select v-model="editedItem.type_of_schedule" :items="itemTypeOfContract" label="Type of contract"
                      :readonly="pageRoute === '/employeesform'" :rules="computedRules" required></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-select v-model="editedItem.payment_method" :items="itemPaymentMethod" label="Payment Method"
                      :readonly="pageRoute === '/employeesform'" :rules="computedRules" required></v-select>
                  </v-col>
                </v-row>
                <!-- Other Rates -->
                <v-divider class="mt-4 mb-6"></v-divider>
                <h4>Other Rates</h4>
                <v-divider class="mt-4 mb-6"></v-divider>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.rate_office_staff" label="Rate Office Staff"
                      :readonly="pageRoute === '/employeesform'" :rules="computedRules" required type="number"
                      step="0.1"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.btw_rate" label="BTW Rate"
                      :readonly="pageRoute === '/employeesform'" type="number" step="0.1" :rules="computedRules"
                      required></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.class_c_rate" label="Class C Rate"
                      :readonly="pageRoute === '/employeesform'" type="number" step="0.1" :rules="computedRules"
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.cr_rate" label="CR Rate"
                      :readonly="pageRoute === '/employeesform'" type="number" step="0.1" :rules="computedRules"
                      required></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.ss_rate" label="SS Rate"
                      :readonly="pageRoute === '/employeesform'" type="number" step="0.1" :rules="computedRules"
                      required></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.training_rate" label="Training Rate"
                      :readonly="pageRoute === '/employeesform'" type="number" step="0.1" :rules="computedRules"
                      required></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.corporate_rate" label="Corporate"
                      :readonly="pageRoute === '/employeesform'" type="number" step="0.1" :rules="computedRules"
                      required></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.mechanics_rate" label="Mechanics"
                      :readonly="pageRoute === '/employeesform'" type="number" step="0.1" :rules="computedRules"
                      required></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.traveling_time_rate" label="Traveling Time"
                      :readonly="pageRoute === '/employeesform'" type="number" step="0.1" :rules="computedRules"
                      required></v-text-field>
                  </v-col>
                </v-row>
                <v-divider class="mt-4 mb-6"></v-divider>
                <h4>Bonuses</h4>
                <v-divider class="mt-4 mb-6"></v-divider>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.amount_bonus" label="Amount Bonus"
                      :readonly="pageRoute === '/employeesform'" type="number" step="0.1" :rules="computedRules"
                      required></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="editedItem.bonus_type" label="Bonus Type"
                      :readonly="pageRoute === '/employeesform'" type="number" step="0.1" :rules="computedRules"
                      required></v-text-field>
                  </v-col>
                </v-row>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="black" text @click="back" :disabled="tab === null || tab === 0">
          Back
        </v-btn>
        <v-btn color="green" text @click="profileSave"
          :disabled="(this.editedItem.form_status && this.pageRoute === '/employeesform') || ((this.department !== 'Human Resources Department' && this.department !== 'Information Technology Department' && this.department !== 'Learning and Development Department') && this.pageRoute === '/employees')">
          Save
        </v-btn>
        <v-btn color="blue" text @click="next" :disabled="tab === 5">
          Next
        </v-btn>
        <!-- <v-btn color="blue" text @click="test">
          Test
        </v-btn> -->
      </v-card-actions>
      <file-modal :modalOpen.sync="modalOpen" :title="modalTitle" :dataFileUrl="fileUrl" :formStatus="formStatus"
        @save="save"></file-modal>
      <div class="loader-container" v-if="showLoader">
        <div class="loader"></div>
      </div>
    </v-card>
  </div>
</template>

<script>
import SignaturePad from 'signature_pad';
//import Employees from '../../components/Employees/Employees.vue';
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { ADD_NEW_EMPLOYEE, CHANGE_STATUS_EMPLOYEE, LOCATIONS_ENDPOINT, GET_EMPLOYEE_BY_EMPLOYEE_NUMBER, UPLOAD_SIGNATURE } from '../../utils/endpoints';
import { bus } from '../../main.js';
import Swal from 'sweetalert2';
import { integer } from 'vuelidate/lib/validators';
import { get_employee_number } from '../../utils/get_employee_number.js'
import FileModal from '../../components/Modals/FileModal.vue'
import PolicyModal from '../../components/Modals/PolicyModal.vue'
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas'; // Importar html2canvas
import { PDFDocument } from 'pdf-lib';
import { generatePublicURL } from '../../utils/presignedUrl.js'
import { uploadFile } from '../../utils/upload_file.js'
import { generatePDF_jobOffer } from '../../utils/generate_pdf_job_offer.js'
import { updateEmployee, fetchEmployeeJobOffer, sendJobOfferEmail } from '../../utils/employees_CRUD.js'
import { fetchVehicles } from '../../utils/vehicles_CRUD.js'
import { getAllReportTo } from '../../utils/Employees/report_toCRUD'


export default {
  name: 'EmployeesCard',
  components: {
    FileModal,
    PolicyModal,
  },
  props: {
    message: String,
    newEmployee: Object,
    index: integer,
    department: String,
  },
  mixins: [validationMixin],  
  data() {
    return {
      raceSelected: '',
      // LOCATION 
      locationSelected: null,
      // REPORT TO
      reportToSelected: null,
      reportToItem: [],
      objReportTo: {},/*  {
        name: '',
        last_name: ''
      }, */
      arrayReportTo: [],

      formValid: false, // Controla el estado de validación del formulario
      carSelected: null,
      typeOfComissionSelected: null,
      itemTypeOfComissions: [],
      rules: {
        required: value => {
          // Verifica si el valor es un objeto (como en el caso de un combobox con return-object)
          if (typeof value === 'object') {
            /* eturn value && value.location ? true : 'Required';  // Verifica si el campo 'location' existe en el objeto */
            if (value && (value.name || value.location)) {
              return true;  // Si existe 'name' o 'location', es válido
            } else {
              return 'Required';
            }
          }
          // Si no es un objeto, valida como un valor normal (string o número)
          return value !== null && value !== undefined &&
            (typeof value === 'string' ? value.trim() !== '' : typeof value === 'number' || /^\d+(\.\d+)?$/.test(value))
            || 'Required.';
        },
        onlyNumbers: value => {
          const pattern = /^[0-9]*$/;
          return pattern.test(value) || 'Only numbers are allowed';
        }
      },
      itemVehicles: [],
      show_permit_expiration: false,
      show_permit: false,
      show_type_of_staff: false,
      itemsTypeOfStaff: [
        'N/A',
        'Auditor',
        'Censor',
        'Fleet',
        'Fleet Junior',
        'Instructor Senior',
        'Instructors',
        'Junior',
        'Management',
        'Office Holder',
        'Office Manager',
        'Owner',
        'Requsitions',
        'Sales',
        'Senior'
      ],
      pdfs_array: [],
      // Generate PDF Job Offer
      job_offer_pdf: false,
      itemsReport: [],
      itemsReport_to: [],
      itemstype_of_position: [],
      itemsDepartment: ["Sales Department",
        "Retentions Department",
        "Calendar Department",
        "Human Resources Department",
        "Accounting Department",
        "Education Department",
        "Corporate Department",
        "Marketing Department",
        "Learning and Development Department",
        "Information Technology Department",
        "Fleet Department"],
      // Signature
      drawingEnabled: true,
      signatureUrl: '',
      statusPolicy: false,
      signaturePad: null,
      signaturePad2: null,
      signatureImage: '',
      //////////////////////////
      showLoader: false,
      pdfContent: null,
      jobOfferFile: null,
      //File_Modal
      formStatus: false,
      modalOpen: false,
      modalTitle: '',
      fileUrl: '',
      //Policy_Modal      
      policyModalOpen: false,
      policyModalTitle: '',
      policyFileUrl: '',
      policy: '',
      ////////////
      pageRoute: window.location.pathname,
      parentInfo: '',
      cardState: this.value,
      fatherEditedItem: this.editedItemFather,
      dialogTabs: false,
      tab: null,
      itemsTabs: ['Job Information', 'Accounting', 'Other Data', 'Personal Information', 'Documents', 'Policies'],
      //Files
      recentPhotoFile: null,
      bachellorDegreeFile: null,
      resumeFile: null,
      idFile: null,
      passportFile: null,
      ////////////////////////////
      tax_certificate_File: null,
      invoice_example_File: null,
      bank_certificate_File: null,
      work_authorization_File: null,
      w4_File: null,
      i9_File: null,
      il_File: null,
      w9_File: null,
      ssn_File: null,
      ////Policies
      employee_handbook_File: null,
      acknowledgement_of_handbook_File: null,
      code_of_discipline_policy_File: null,
      dress_code_policy_File: null,
      sales_enrollment_policy_File: null,
      attendance_policy_File: null,
      advances_and_loan_policy_File: null,
      no_show_and_late_cancellation_File: null,
      sexual_harassment_training_video_File: null,
      sexual_harassment_training_acknowledgement_File: null,
      timekeeping_policy_File: null,
      remote_staff_working_policy_File: null,
      cash_handling_policy_File: null,
      acknowledgement_invoice_submission_File: null,
      // Fleet
      vehicle_use_and_care_File: null,
      ///////////////////////////
      dialog: false,
      dialogDelete: false,
      search: '',
      menu: false,
      menuSince: false,
      menuHireDate: false,
      menuBirthdate: false,
      menu_permit_expiration: false,
      greenColumns: ['mondaySchedule', 'tuesdaySchedule', 'wednesdaySchedule', 'thursdaySchedule', 'fridaySchedule', 'saturdaySchedule', 'sundaySchedule'],
      editedIndex: -1,
      editedItem: {
        form_status: false,
        employee_number: '',
        company: '',
        name: '',
        last_name: '',
        gender: '',
        //role: '',
        permissions: 'Employee',
        department: '',
        department2: '',
        position: '',
        type_of_staff: 'N/A',
        type_of_position: 'N/A',
        type_of_job: '',
        nova_email: 'example@novadriving.com',
        personal_phone: '',
        address: '',
        postal_code: '',
        country: '',
        state: '',
        city: '',
        birthdate: null,
        id_type: '',
        id_number: '',
        report_to: false,
        //report: '',
        report: [],/* 
            { 'name': 'test', 'last_name': 'test' },
            { 'name': 'test', 'last_name': 'test' },
            { 'name': 'test', 'last_name': 'test' },
            { 'name': 'test', 'last_name': 'test' }, */
        dn_name: '',
        hire_date: null,
        end_date: null,
        //years_with_nova: 0.0,
        type_of_income: 'Fixed',
        rate_office_staff: 0.0,
        btw_rate: 0.0,
        class_c_rate: 0.0,
        cr_rate: 0.0,
        ss_rate: 0.0,
        training_rate: 0.0,
        assignment_rate: 0.0,
        commissions: 0.0,
        personal_email: 'example@gmail.com',
        phone_8x8: 'N/A',
        extension_phone_8x8: 'N/A',
        location: '',
        address_location: '',
        preferences: '',
        /* job_offer: false, */
        type_of_schedule: 'N/A',
        //porcentage_of_docs: 0.0,
        payment_method: 'N/A',
        activity_details: '',
        job_change_reason: '',
        worker_category: '',
        receives_comissions: 'N/A',
        pay_frequency: 'Monthly',
        marital_status: '',
        ethnicity: '',
        race: '',
        medicare: '',
        medicaid: '',
        tabacco_user: '',
        name_emergency: '',
        last_name_emergency: '',
        phone_emergency: '',
        address_emergency: '',
        relationship_emergency: '',
        since: null,
        days: [
          { day_of_week: 'Monday', checked: false, start_time: '09:00:00', end_time: '18:00:00', startTimeMenu: false, endTimeMenu: false, employee_number: '' },
          { day_of_week: 'Tuesday', checked: false, start_time: '09:00:00', end_time: '18:00:00', startTimeMenu: false, endTimeMenu: false, employee_number: '' },
          { day_of_week: 'Wednesday', checked: false, start_time: '09:00:00', end_time: '18:00:00', startTimeMenu: false, endTimeMenu: false, employee_number: '' },
          { day_of_week: 'Thursday', checked: false, start_time: '09:00:00', end_time: '18:00:00', startTimeMenu: false, endTimeMenu: false, employee_number: '' },
          { day_of_week: 'Friday', checked: false, start_time: '09:00:00', end_time: '18:00:00', startTimeMenu: false, endTimeMenu: false, employee_number: '' },
          { day_of_week: 'Saturday', checked: false, start_time: '09:00:00', end_time: '18:00:00', startTimeMenu: false, endTimeMenu: false, employee_number: '' },
          { day_of_week: 'Sunday', checked: false, start_time: '09:00:00', end_time: '18:00:00', startTimeMenu: false, endTimeMenu: false, employee_number: '' },
        ],
        observations: '',
        // Schedule string
        mondaystr: '',
        tuesdaystr: '',
        wednesdaystr: '',
        thursdaystr: '',
        fridaystr: '',
        saturdaystr: '',
        sundaystr: '',
        // Documents
        recentphotourl: false,
        bachellordegreeurl: false,
        resumeurl: false,
        idurl: false,
        passporturl: false,
        tax_certificateurl: false,
        invoice_exampleurl: false,
        bank_certificateurl: false,
        work_authorizationurl: false,
        w4url: false,
        i9url: false,
        ilurl: false,
        w9url: false,
        ssnurl: false,
        job_offerurl: false,
        //Policies
        employee_handbookurl: false,
        acknowledgement_of_handbookurl: false,
        code_of_discipline_policyurl: false,
        dress_code_policyurl: false,
        sales_enrollment_policyurl: false,
        attendance_policyurl: false,
        advances_and_loan_policyurl: false,
        no_show_and_late_cancellationurl: false,
        sexual_harassment_training_acknowledgementurl: false,
        timekeeping_policyurl: false,
        remote_staff_working_policyurl: false,
        cash_handling_policyurl: false,
        // Fleet 
        vehicle_use_and_careurl: false,
        // Videos
        sexual_harassment_training_videourl: false,
        dress_code_policy_videourl: false,
        time_clock_wizard_videourl: false,
        //Forms permissions
        walk_ins: false,
        training_logs: false,
        job_offer: false,
        // Danubanet
        danubanet_name_1: 'N/A',
        danubanet_name_2: 'N/A',
        // Instructors
        permit: '',
        permit_expiration: null,
        // Fleet
        assigned_vehicle: 'N/A',
        vehicle_name: '',
        // Bonus
        amount_bonus: 0.0,
        bonus_type: 0.0,
        // Additional Hourly Rates Detail
        type_of_comissions: '',
        // Other Rates
        training_rate: 0.0,
        corporate_rate: 0.0,
        mechanics_rate: 0.0,
        traveling_time_rate: 0.0,
        veteran: 'N/A',
      },
      defaultItem: {
        employee_number: '',
        company: '',
        name: '',
        last_name: '',
        gender: '',
        //role: '',
        permissions: 'Employee',
        department: '',
        department2: '',
        position: '',
        type_of_staff: 'N/A',
        type_of_position: 'N/A',
        type_of_job: '',
        nova_email: 'example@novadriving.com',
        personal_phone: '',
        address: '',
        postal_code: '',
        country: '',
        state: '',
        city: '',
        birthdate: null,
        id_type: '',
        id_number: '',
        report_to: false,
        /* report: '', */
        report: [],
        dn_name: '',
        hire_date: null,
        end_date: null,
        //years_with_nova: 0.0,
        type_of_income: 'Fixed',
        rate_office_staff: 0.0,
        btw_rate: 0.0,
        class_c_rate: 0.0,
        cr_rate: 0.0,
        ss_rate: 0.0,
        training_rate: 0.0,
        assignment_rate: 0.0,
        commissions: 0.0,
        personal_email: 'example@gmail.com',
        phone_8x8: 'N/A',
        extension_phone_8x8: 'N/A',
        location: '',
        address_location: '',
        preferences: '',
        /* job_offer: '', */
        type_of_schedule: 'N/A',
        //porcentage_of_docs: 0.0,
        payment_method: 'N/A',
        activity_details: '',
        job_change_reason: '',
        worker_category: '',
        receives_comissions: 'N/A',
        pay_frequency: 'Monthly',
        marital_status: '',
        ethnicity: '',
        race: '',
        medicare: '',
        medicaid: '',
        tabacco_user: '',
        name_emergency: '',
        last_name_emergency: '',
        phone_emergency: '',
        address_emergency: '',
        relationship_emergency: '',
        since: null,
        days: [
          { day_of_week: 'Monday', checked: false, start_time: '09:00:00', end_time: '18:00:00', startTimeMenu: false, endTimeMenu: false, employee_number: '' },
          { day_of_week: 'Tuesday', checked: false, start_time: '09:00:00', end_time: '18:00:00', startTimeMenu: false, endTimeMenu: false, employee_number: '' },
          { day_of_week: 'Wednesday', checked: false, start_time: '09:00:00', end_time: '18:00:00', startTimeMenu: false, endTimeMenu: false, employee_number: '' },
          { day_of_week: 'Thursday', checked: false, start_time: '09:00:00', end_time: '18:00:00', startTimeMenu: false, endTimeMenu: false, employee_number: '' },
          { day_of_week: 'Friday', checked: false, start_time: '09:00:00', end_time: '18:00:00', startTimeMenu: false, endTimeMenu: false, employee_number: '' },
          { day_of_week: 'Saturday', checked: false, start_time: '09:00:00', end_time: '18:00:00', startTimeMenu: false, endTimeMenu: false, employee_number: '' },
          { day_of_week: 'Sunday', checked: false, start_time: '09:00:00', end_time: '18:00:00', startTimeMenu: false, endTimeMenu: false, employee_number: '' },
        ],
        observations: '',
        mondaystr: '',
        tuesdaystr: '',
        wednesdaystr: '',
        thursdaystr: '',
        fridaystr: '',
        saturdaystr: '',
        sundaystr: '',
        // Documents
        recentphotourl: false,
        bachellordegreeurl: false,
        resumeurl: false,
        idurl: false,
        passporturl: false,
        tax_certificateurl: false,
        invoice_exampleurl: false,
        bank_certificateurl: false,
        work_authorizationurl: false,
        w4url: false,
        i9url: false,
        ilurl: false,
        w9url: false,
        ssnurl: false,
        job_offerurl: false,
        //Policies
        employee_handbookurl: false,
        acknowledgement_of_handbookurl: false,
        code_of_discipline_policyurl: false,
        dress_code_policyurl: false,
        sales_enrollment_policyurl: false,
        attendance_policyurl: false,
        advances_and_loan_policyurl: false,
        no_show_and_late_cancellationurl: false,
        sexual_harassment_training_acknowledgementurl: false,
        timekeeping_policyurl: false,
        remote_staff_working_policyurl: false,
        cash_handling_policyurl: false,
        acknowledgement_invoice_submissionurl: false,
        // Fleet 
        vehicle_use_and_careurl: false,
        // Videos
        sexual_harassment_training_videourl: false,
        dress_code_policy_videourl: false,
        time_clock_wizard_videourl: false,
        //////////////////////////
        //Forms permissions
        walk_ins: false,
        training_logs: false,
        job_offer: false,
        // Danubanet
        danubanet_name_1: 'N/A',
        danubanet_name_2: 'N/A',
        // Instructors
        permit: '',
        permit_expiration: null,
        // Fleet
        assigned_vehicle: 'N/A',
        vehicle_name: '',
        // Bonus
        amount_bonus: 0.0,
        bonus_type: 0.0,
        // Additional Hourly Rates Detail
        type_of_comissions: '',
        // Other Rates
        training_rate: 0.0,
        corporate_rate: 0.0,
        mechanics_rate: 0.0,
        traveling_time_rate: 0.0,
        veteran: 'N/A',
      },
      employees: [],
      selectedGender: null,
      itemsGender: [
        'Male',
        'Female'
      ],
      //selectedRole: null,
      itemsPosition: ["Director",
        "Manager",
        "Coordinator",
        "Supervisor",
        "Specialist",
        "Analyst",
        "Assistant",
        "Support",
        "Operator",
        "Instructor",
        "Teacher"],
      itemsPermissions: ["Administrator",
        "Analyst",
        "Client",
        "Consultant",
        "Coordinator",
        "Developer",
        "Employee",
        "Manager",
        "Supervisor",
        "Technical Support"],
      itemCompany: [
        'Nova Driving School',
        'V-Outsourcing',
        'V-Outsourcing/Gusto'
      ],
      itemReportTo: [
        "Vanessa Arcos",
        "Silvana Reyes",
        "Pablo Abad",
        "Jenny Villegas",
        "Diana Villegas",
        "Anthony Arguello",
        "Kevin Mendez",
        "Paola Torres",
        "Kathy Carrera",
        "Ana María Gallegos",
        "Dana Doherty"],
      itemWorkerCategory: [
        "Independent Contract",
        "Professional Service",
        "Employee"
      ],
      itemRate: [
        /* "Monthly", */
        /*  "SemiMonthly", */
        "Fixed",
        "Fixed with variations",
        "Hourly",
        "Hourly with varations"
      ],
      itemDecision: [
        "Yes",
        "N/A"
      ],
      itemPayFrequency: [
        "SemiMonthly",
        "Monthly",
        /* "N/A" */
      ],
      itemTypeOfContract: [
        "Part Time",
        "Full Time",
        "N/A"
      ],
      itemPaymentMethod: [
        "Cash",
        "International Transfer",
        "Direct Deposit",
        "N/A"
      ],
      itemLocations: [], // Get info from locations table
      itemIdType: [
        "ID",
        "Passport"
      ],
      itemMaritalStatus: [
        "Single",
        "Civil PartnerShip",
        "Married",
        "Divorced",
        "Separated",
        "Legally Separated",
        "Domestic Partner",
        "Widowed"
      ],
      itemEthnicity: [
        "Hispanic or Latino",
        "Other"
      ],
      itemRace: [
        "American Indian or Alaska Native",
        "Asian",
        "Black or African American",
        "Native Hawaiian or other pacific islander",
        "Two or more races (Not Hispanic or Latino)",
        "White",
        "Other"
      ],
      itemTypeOfJob: [
        "BTW Instructor",
        "CR Teacher",
        "BTW Instructor & CR Teacher",
        "Facilities Support",
        "Field Maintenance Operator",
        "Customer Service & Sales Representative - Remote",
        "Customer Service & Sales Representative - Office Staff",
        "Admin Staff - Remote",
        "Admin Staff - Office Staff"
      ],
      policies: [
        "Employee Handbook",
        "Acknowledgement of Handbook",
        "Code of Discipline Policy",
        "Dress Code Policy",
        "Sales Enrollment Policy",
        "Attendance Policy",
        "Advances and Loan Policy",
        "Now show and Late cancelation",
        "Sexual Harassment Training video",
        "Sexual Harassment Training Acknowledgement",
        "Timekeeping Policy",
        "Remote Staff Working Policy",
        "Cash Handling Policy",
        "Vehicle Use And Care"
      ],
    };
  },
  computed: {
    buttonClasses() {
      return {
        'light-green-background': this.isFormComplete,
        'disabled-background': !this.isFormComplete,
      };
    },
    isFormComplete() {
      return (
        this.reportToSelected
      );
    },
    employeeRules() {
      // Define las reglas basadas en la ruta actual
      if (this.pageRoute === '/employeesform') {
        return [this.rules.required];
      }
      // Devuelve reglas vacías o diferentes si la ruta no coincide
      return [];
    },
    /* positionRules(){
      if (this.pageRoute === '/employees' && (this.editedItem.position === 'Instructor' || this.editedItem.position === 'Operator')) {
        return [this.rules.required];
      }
      // Devuelve reglas vacías o diferentes si la ruta no coincide
      return [];
    }, */
    positionRules() {
      if (this.pageRoute === '/employees' && (this.editedItem.position === 'Instructor' || this.editedItem.position === 'Operator')) {
        return [this.rules.required];
      }
      // Devuelve reglas vacías o diferentes si la ruta no coincide
      return [];
    },
    permitRules() {
      if (this.pageRoute === '/employees' && this.editedItem.position === 'Instructor') {
        return [this.rules.required];
      }
      // Devuelve reglas vacías o diferentes si la ruta no coincide
      return [];
    },
    comissionRules() {
      if (this.pageRoute === '/employees' && this.editedItem.receives_comissions === 'Yes') {
        return [this.rules.required];
      }
      // Devuelve reglas vacías o diferentes si la ruta no coincide
      return [];
    },

    vehicleRules() {
      // Define las reglas basadas en la ruta actual
      if (this.pageRoute === '/employees' && this.editedItem.assigned_vehicle === 'Yes') {
        if (!this.carSelected) {
          return [this.rules.required];
        }
      }
      // Devuelve reglas vacías o diferentes si la ruta no coincide
      return [];
    },
    computedRules() {
      // Define las reglas basadas en la ruta actual
      if (this.pageRoute === '/employees') {
        return [this.rules.required];
      }
      // Devuelve reglas vacías o diferentes si la ruta no coincide
      return [];
    },
    formTitle() {
      return this.editedIndex === -1 ? 'New Employee' : 'Employee'
    },
    filteredEmployees() {
      return this.employees.filter(employee =>
        employee.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  async mounted() {
    console.log("<------------------- EmployeesCard - MOUNTED ------------------->");
    bus.$emit('login-success');
    bus.$emit('permissions');
    this.getLocations();
    this.reportToItem = await getAllReportTo();
    console.log("this.reportToItem: ", this.reportToItem)
    this.itemVehicles = await fetchVehicles();
    if (this.newEmployee != null && this.index != null) {
      if (this.index != -1) {
        // UPDATING
        console.log("this.newEmployee: ", this.newEmployee);
        this.editedItem = this.newEmployee; //Employee from father
        console.log(">>>>>>> this.editedItem: ", this.editedItem);
        this.editedIndex = this.index //Index from father
        this.locationSelected = this.itemLocations && this.editedItem.location
          ? this.itemLocations.find(location => location.location === this.editedItem.location)
          : null;
        this.carSelected = this.itemVehicles.find(vehicle => vehicle.name === this.editedItem.vehicle_name);
      }
    } else {
      // EMPLOYEE FORM
      console.log("EMPLOYEE FORM..............");
      this.editedItem = await this.get_EmployeeData();
      console.log("this.editedItem...... ", this.editedItem);
      this.carSelected = this.itemVehicles.find(vehicle => vehicle.name === this.editedItem.vehicle_name);
      this.formStatus = this.editedItem.form_status;
      this.editedIndex = this.editedItem.id;
      if (this.itemRace.includes(this.editedItem.race)) {
        this.raceSelected = this.editedItem.race
      } else {
        this.raceSelected = "Other"
      }
      console.log("this.editedIndex: ", this.editedIndex);
      this.locationSelected = this.itemLocations && this.editedItem.location
        ? this.itemLocations.find(location => location.location === this.editedItem.location)
        : null;
      if (this.editedItem.form_status) {
        if (this.signatureUrl === '' && this.signatureImage === '') {
          const resUrl = await generatePublicURL(`Employees/${this.editedItem.employee_number}`, 'digital_signature.png');
          if (resUrl.status != 404) {
            this.signatureUrl = resUrl.url;
          }
        }
      }
      this.handlePositionChange(this.editedItem.position);
    }
  },
  watch: {
    raceSelected(val) {
      console.log("raceSelected: ", val);
      if (val != 'Other') {
        this.editedItem.race = val;
      }

    },
    itemLocations(newVal) {
      if (newVal && this.editedItem.location) {
        this.locationSelected = newVal.find(location => location.location === this.editedItem.location);
      }
    },
    locationSelected(item) {
      console.log("locationSelected: ", item);
      this.editedItem.location = item.location;
    },
    'editedItem.assigned_vehicle'(val) {
      if (val === 'N/A') {
        this.editedItem.vehicle_name = "";
        this.carSelected = null;
      }
    },
    carSelected(item) {
      console.log("carSelected..... ", item);
      if (item != null) {
        this.editedItem.vehicle_name = item.name;
      }

    },
    'editedItem.receives_comissions'(val) {
      //console.log("editedItem.receives_comissions: ", val);
      if (val === "N/A") {
        this.editedItem.type_of_comissions = "";
        this.editedItem.commissions = null;
        this.typeOfComissionSelected = null;

      }
    },
    typeOfComissionSelected(item) {
      console.log("WATCH - itemTypeOfComissionSelected: ", item);
      if (item != null) {
        this.editedItem.type_of_comissions = item.comission;
        this.editedItem.commissions = item.porcentage; // Porcentage %
      }
    },
    'editedItem.position'(val) {
      this.handlePositionChange(val);
    },
    async tab(newVal) {
      if (newVal === 3) {
        if (this.signaturePad === null) {
          this.$nextTick(() => {
            requestAnimationFrame(() => {
              this.initSignaturePad();
              if (this.signatureUrl != '') {
                this.loadImageToCanvas(this.signatureUrl);
              }
            });
          });
        }
      }
    },
    value(newValue) {
      this.cardState = newValue;
      if (this.cardState == false) {
        this.close();
      }
    },
    dialogTabs(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.close();
    },
  },
  methods: {
    async get_job_offer() {
      if (this.editedItem.form_status) {
        console.log("employee_number: ", this.editedItem.employee_number);
        this.jobOfferFile = await fetchEmployeeJobOffer(this.editedItem.employee_number);
        console.log("this.jobOfferFile ", this.jobOfferFile);
        //this.jobOfferFile = new File([combinedPdfBlob], "job_offer.pdf", { type: 'application/pdf' });
        this.modalTitle = "The form has been successfully filled out";
        this.formStatus = this.editedItem.form_status;
        this.fileUrl = URL.createObjectURL(this.jobOfferFile);
        this.modalOpen = true;
      }
    },
    phoneValidation(value) {
      const phoneRegex = /^[\d\s()+-]+$/;
      return phoneRegex.test(value) || 'Only digits, spaces, parentheses, and + are allowed';
    },
    addItem() {
      try {
        if (this.reportToSelected) {
          if (!Array.isArray(this.editedItem.report)) {
            this.editedItem.report = [];
          }
          this.editedItem.report.push(this.reportToSelected);
          this.reportToSelected = null;
        }
      } catch (error) {
        console.error("Error adding coverage: ", error);
      }
    },
    removeDriver(index) {
      console.log("Removing....");
      this.editedItem.report.splice(index, 1);
    },
    concatName(item) {
      return `${item.name} ${item.last_name}`;
    },
    test() {
      if (this.$refs.form.validate()) {
        console.log("Valido...");
      }
      else {
        console.log("NO Valido...");
      }
    },
    load_signature_jobOffer(signatureImage) {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          if (signatureImage) {
            const canvas = this.$refs.signaturePadCanvas;
            const context = canvas.getContext('2d');
            const image = new Image();
            image.onload = () => {
              context.drawImage(image, 0, 0, canvas.width, canvas.height);
              resolve();
            };
            image.onerror = reject;
            image.src = signatureImage;
          } else {
            resolve();
          }
        });
      });
    },
    blobToArrayBuffer(blob) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsArrayBuffer(blob);
      });
    },
    /* 
    Make a merge with various pdfs
    Return a blob to open in a modal
    */
    async mergePDFs(pdfs) {
      try {
        const mergedPdf = await PDFDocument.create();

        for (const pdfBlob of pdfs) {
          const pdfBytes = await this.blobToArrayBuffer(pdfBlob);
          const pdfDoc = await PDFDocument.load(pdfBytes);
          const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
          copiedPages.forEach((page) => {
            mergedPdf.addPage(page);
          });
        }

        const mergedPdfBytes = await mergedPdf.save();
        return new Blob([mergedPdfBytes], { type: 'application/pdf' });
      } catch (error) {
        //console.error("Error merging PDFs: ", error);
        throw error; // Re-lanza el error para que pueda ser manejado por quien llame a esta función
      }
    },
    async create_pdfSignature() {
      try {
        this.$nextTick(() => {
          requestAnimationFrame(async () => {
            this.get_signatureUrl(this.signaturePad);
            ////console.log("this.signatureImage: ", this.signatureImage);
            await this.load_signature_jobOffer(this.signatureImage);
            //this.captureSignature();
            const pdf = new jsPDF({
              unit: 'px',
              format: [1000, 2000], // Ajustar para que coincida con generatePDF_jobOffer
              orientation: 'portrait'
            });

            //const element = document.getElementById('signature_canvas');
            const element = document.getElementById('signature-job-offer');
            //const element = document.getElementById('captured_signature_canvas');
            this.job_offer_pdf = false;
            ////console.log("element: ", element);
            if (element) {
              const canvas = await html2canvas(element);
              const imgData = canvas.toDataURL('image/png');
              const newWidth = 1000 - 20; // Ajustar ancho para coincidir con el formato de generatePDF_jobOffer
              const newHeight = (canvas.height / canvas.width) * newWidth;
              const margin = 10;

              pdf.internal.pageSize.height = newHeight + margin * 2;
              pdf.addImage(imgData, 'PNG', margin, margin, newWidth, newHeight);

              const pdfBlob = pdf.output('blob');
              this.pdfs_array.push(pdfBlob);
              ////console.log("this.pdfs_array after adding signature: ", this.pdfs_array);

              //const combinedPdfBlob = await this.combinePDFsOnSinglePage(this.pdfs_array);
              const combinedPdfBlob = await this.mergePDFs(this.pdfs_array);
              this.jobOfferFile = new File([combinedPdfBlob], "job_offer.pdf", { type: 'application/pdf' });
              this.modalTitle = "The form has been successfully filled out";
              this.formStatus = this.editedItem.form_status;
              this.fileUrl = URL.createObjectURL(combinedPdfBlob);
              this.modalOpen = true;
              this.showLoader = false;
            } else {
              //console.error("Element #signature_canvas not found.");
            }
          });
        });
      } catch (error) {
        ////console.log("error: ", error);
      }

    },
    generate_job_offer() {
      this.job_offer_pdf = true;
      requestAnimationFrame(async () => {
        const element = document.querySelector('#job_offer_pdf');
        ////console.log("signatureUrl: ", this.signatureUrl);
        ////console.log("element: ", element);

        if (element) {
          const job_offer = await generatePDF_jobOffer(element);
          this.pdfs_array.push(job_offer);
        } else {
          //console.error("Element #job_offer_pdf not found.");
        }
        ////console.log("this.pdfs_array: ", this.pdfs_array);
        this.create_pdfSignature();
      });
    },
    handlePositionChange(itemSelected) {
      /* if (this.pageRoute === '/employees') {
        this.$v.editedItem.position.$touch();
      } */
      ////console.log("itemSelected: ", itemSelected);
      if (itemSelected === 'Operator') {
        ////console.log("Operator Supervisor...... ");
        //this.itemsReport = ['N/A', 'Ximena Izuerita', 'Leslie Flores', 'Betzabeth Pereira', 'Ana Soto'];
        this.itemstype_of_position = ["N/A", "Operator Supervisor", "Senior Operator"];
        this.show_type_of_staff = false;
        this.editedItem.permit = '';
        this.editedItem.permit_expiration = null;
        this.editedItem.type_of_staff = 'N/A';
      } else if (itemSelected === 'Instructor') {
        ////console.log("Instructor...... ");
        //this.itemsReport = ['N/A', 'Sebastian Lopez', 'David Bonilla', 'Frank Valencia'];
        this.itemstype_of_position = ["N/A", "Lead Instructor"];
        this.show_type_of_staff = true;
        //this.show_permit = true;
        //this.show_permit_expiration = true;
      } else {
        this.show_type_of_staff = false;
        this.editedItem.permit = '';
        this.editedItem.permit_expiration = null;
        this.editedItem.type_of_staff = 'N/A';
      }
    },
    loadImageToCanvas(imageUrl) {
      const canvas = document.querySelector('.signature-pad-canvas');
      ////console.log("canvas: ", canvas);
      if (!canvas) {
        //console.error('Canvas no encontrado');
        return;
      }

      const context = canvas.getContext('2d');
      if (!context) {
        //console.error('Contexto 2D no disponible');
        return;
      }

      const image = new Image();
      image.src = imageUrl;

      image.onload = () => {
        canvas.width = image.width;
        canvas.height = image.height;
        context.drawImage(image, 0, 0);
        //this.signaturePad.fromDataURL(canvas.toDataURL()); // Load imageURL to signaturePad
        this.drawingEnabled = false;
        canvas.style.pointerEvents = 'none';

      };
      this.signaturePad.fromDataURL(canvas.toDataURL());
      image.onerror = (error) => {
        //console.error('Error al cargar la imagen', error);
      };
      return true
    },
    async upload_signature(signature) {
      try {
        const data = {
          "employee_number": this.editedItem.employee_number,
          "signature_base64": signature,
          "signature": "digital_signature"
        };

        const response = await fetch(UPLOAD_SIGNATURE, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        });

        if (!response.ok) {
          const errorResponse = await response.json();
          const errorMessage = errorResponse.error || 'Unknown error occurred';
          throw new Error(errorMessage);
        }

        const responseData = await response.json();
        console.log('File uploaded successfully:', responseData);
      } catch (error) {
        if (error instanceof Error) {
          console.error('Error uploading file:', error.message);
        } else {
          console.error('Unknown error occurred:', error);
        }
      }
    },
    get_signatureUrl(signaturePad) {
      try {
        ////console.log("<<<<<<<<<<<<<< GETTING SIGNATURE URL.......");
        ////console.log("signaturePad: ", signaturePad);
        this.signatureImage = signaturePad.toDataURL(); // Asigna la firma a la variable 
      } catch (error) {
        //console.error("Error Signature to Data URL");
      }

    },
    clearPad() {
      this.signaturePad.clear();
    },
    initSignaturePad() {
      const canvas = document.querySelector('.signature-pad-canvas');
      const container = document.querySelector('.signature-pad-container');
      ////console.log("canvas: ", canvas);
      ////console.log("container: ", container);

      if (!canvas || !container) {
        //console.error('Canvas or container element not found.');
        return;
      }

      // Ajustar el tamaño del canvas al contenedor
      canvas.width = container.clientWidth;
      canvas.height = container.clientHeight;

      this.signaturePad = new SignaturePad(canvas, {
        backgroundColor: 'rgb(255, 255, 255)',
        penColor: 'rgb(0, 0, 0)',
        minWidth: 1,
        maxWidth: 3.5,
      });

      // Asegurar que el canvas se actualice correctamente después de inicializar SignaturePad
      //this.signaturePad.clear(); // Limpiar el canvas inicialmente si es necesario
      //this.signaturePad = null;
      return true
    },
    accepted_policy() {
      ////console.log("Policy accepted......", this.policy);
      this.editedItem[this.policy] = true;
      ////console.log("this.editedItem[this.policy]", this.editedItem[this.policy]);
    },
    validatePolicies(type_of_job, policiesItem, typeOfJobPolicies) {
      console.log("type_of_job: ", type_of_job);
      console.log("policiesItem: ", policiesItem);
      console.log("typeOfJobPolicies: ", typeOfJobPolicies);
      ////console.log("typeOfJobPolicies: ", typeOfJobPolicies);
      const requiredPolicies = typeOfJobPolicies[type_of_job];
      console.log("requiredPolicies: ", requiredPolicies);
      if (!requiredPolicies) {
        console.log(`${type_of_job} not found in policies list.`);
        alert(`${type_of_job} not found in policies list.`);
        return false;
      }

      ////console.log("policiesItem: ", policiesItem)
      ////console.log("requiredPolicies: ", requiredPolicies)

      // Verificar las políticas comunes y específicas para la posición
      for (let i = 0; i < policiesItem.length; i++) {
        if (!policiesItem[i]) {
          console.log("policiesItem[i]: ", i)
          alert("You must accept all policies", policiesItem[i]);
          return false;
        }
      }

      // Verificar las políticas específicas para la posición
      for (let j = 0; j < requiredPolicies.length; j++) {
        const policy = requiredPolicies[j];
        const policyIndex = policiesItem.findIndex(item => item === policy); // Suponiendo que los nombres en policiesItem terminan en 'url'
        if (policyIndex === -1 || !policiesItem[policyIndex]) {
          console.log(`You must accept ${policy}`)
          alert(`You must accept ${policy}`);
          return false;
        }
      }

      // Si todas las validaciones pasan
      return true;
    },
    async profileSave() {
      if (this.$refs.form.validate()) {
        console.log("Válido...");
      }
      else {
        console.log("NO Válido...");
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          //text: 'All fields must be filled out correctly!',
          text: `You must fill out all the fields correctly`,
        });
        return;
      }
      let commonPolicies = [
        this.editedItem.employee_handbookurl,
        this.editedItem.acknowledgement_of_handbookurl,
        this.editedItem.code_of_discipline_policyurl,
        this.editedItem.dress_code_policyurl,
        this.editedItem.attendance_policyurl,
        this.editedItem.advances_and_loan_policyurl,
        this.editedItem.no_show_and_late_cancellationurl,
        this.editedItem.sexual_harassment_training_acknowledgementurl,
        this.editedItem.sexual_harassment_training_videourl,
        this.editedItem.dress_code_policy_videourl,
        this.editedItem.time_clock_wizard_videourl,
      ];
      const typeOfJobPolicies = {
        'BTW Instructor': [
          this.editedItem.sales_enrollment_policyurl,
          this.editedItem.no_show_and_late_cancellationurl,
        ],
        'CR Teacher': [
          this.editedItem.sales_enrollment_policyurl,
          this.editedItem.no_show_and_late_cancellationurl,
        ],
        'BTW Instructor & CR Teacher': [
          this.editedItem.sales_enrollment_policyurl,
          this.editedItem.no_show_and_late_cancellationurl,
        ],
        'Facilities Support': [
          this.editedItem.timekeeping_policyurl
        ],
        'Field Maintenance Operator': [
          /* this.editedItem.timekeeping_policyurl, */
          this.editedItem.no_show_and_late_cancellationurl,
          this.editedItem.sales_enrollment_policyurl,
        ],
        'Customer Service & Sales Representative - Remote': [
          this.editedItem.sales_enrollment_policyurl,
          this.editedItem.remote_staff_working_policyurl,
          /* this.editedItem.cash_handling_policyurl, */
          this.editedItem.no_show_and_late_cancellationurl
        ],
        'Customer Service & Sales Representative - Office Staff': [
          this.editedItem.sales_enrollment_policyurl,
          this.editedItem.cash_handling_policyurl,
          /* this.editedItem.no_show_and_late_cancellationurl */
        ],
        'Admin Staff - Remote': [
          this.editedItem.remote_staff_working_policyurl,
          /* this.editedItem.cash_handling_policyurl, */
          this.editedItem.no_show_and_late_cancellationurl
        ],
        'Admin Staff - Office Staff': [
          this.editedItem.cash_handling_policyurl,
          this.editedItem.no_show_and_late_cancellationurl,
          this.editedItem.sales_enrollment_policyurl,
        ]
      }

      // Función para verificar si alguna propiedad es falsa
      ////console.log("this.editedItem.type_of_job: ", this.editedItem.type_of_job);
      if (this.pageRoute === '/employeesform') {
        if (!this.validatePolicies(this.editedItem.type_of_job, commonPolicies, typeOfJobPolicies)) {
          alert("You must accept all policies");
          return;
        }
      }
      if (this.pageRoute === "/employeesform") {
        console.log("UPDATING EMPLOYEE FORM / GETTING JOB OFFER..................");
        //const res = this.elementsPdf();
        let res = this.validate_documents()
        if (res) {
          await updateEmployee(this.editedItem);
          const file = await fetchEmployeeJobOffer(this.editedItem.employee_number);
          if (file) {
            this.jobOfferFile = file;
            console.log('File successfully stored in jobOfferFile');
            //this.jobOfferFile = new File([combinedPdfBlob], "job_offer.pdf", { type: 'application/pdf' });
            this.modalTitle = "The form has been successfully filled out";
            this.formStatus = this.editedItem.form_status;
            this.fileUrl = URL.createObjectURL(this.jobOfferFile);
            this.modalOpen = true;
          } else {
            console.error('Failed to fetch the file.');
          }
        }
      } else {
        this.save();

      }
    },

    validate_documents() {
      if (this.pageRoute === "/employeesform") {
        if (!this.editedItem.recentphotourl &&
          !this.editedItem.bachellordegreeurl &&
          !this.editedItem.resumeurl &&
          !this.editedItem.idurl &&
          !this.editedItem.passporturl) {
          if (!this.recentPhotoFile ||
            !this.bachellorDegreeFile ||
            !this.resumeFile ||
            !this.idFile ||
            !this.passportFile) {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: `All documents must be uploaded`,
            });
            return;
          } /* else {
            return true;
          } */
        } /* else {
          return true;
        } */
        if (this.editedItem.company === 'Nova Driving School') {
          if (this.editedItem.location != 'México' && this.editedItem.location != 'Ecuador' && this.editedItem.location != 'Colombia') { //Work Auth, W4, i9, il
            if (!this.editedItem.work_authorizationurl &&
              !this.editedItem.w4url &&
              !this.editedItem.i9url &&
              !this.editedItem.ilurl &&
              !this.editedItem.ssnurl) {
              if (!this.work_authorization_File ||
                !this.w4_File ||
                !this.i9_File ||
                !this.il_File ||
                !this.ssn_File) {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: `All documents must be uploaded`,
                });
                return;
              } else {
                return true;
              }
            } else {
              return true;
            }
          }
        }
        if (this.editedItem.company === 'V-Outsourcing') {
          if (this.editedItem.location != 'México' && this.editedItem.location != 'Ecuador' && this.editedItem.location != 'Colombia') {//W9
            if (!this.editedItem.w4url) {
              if (!this.w9_File) {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: `All documents must be uploaded`,
                });
                return;
              } else {
                return true;
              }
            } else {
              return true;
            }
          } else {//RUC-RIMPE-SAT, Invoice example, Bank certificate
            if (!this.editedItem.tax_certificateurl &&
              !this.editedItem.invoice_exampleurl &&
              !this.editedItem.bank_certificateurl) {
              if (!this.tax_certificate_File ||
                !this.invoice_example_File ||
                !this.bank_certificate_File) {
                Swal.fire({
                  icon: 'error',
                  title: 'Oops...',
                  text: `All documents must be uploaded`,
                });
                return;
              } else {
                return true;
              }
            } else {
              return true;
            }
          }
        }
      }
    },
    async elementsPdf() {
      try {
        if (this.pageRoute === "/employeesform") {
          if (this.editedItem.recentphotourl != '1' &&
            this.editedItem.bachellordegreeurl != '1' &&
            this.editedItem.resumeurl != '1' &&
            this.editedItem.idurl != '1' &&
            this.editedItem.passporturl != '1') {
            if (!this.recentPhotoFile ||
              !this.bachellorDegreeFile ||
              !this.resumeFile ||
              !this.idFile ||
              !this.passportFile) {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `All documents must be uploaded`,
              });
              return;
            }
          }
          if (this.editedItem.company === 'Nova Driving School') {
            if (this.editedItem.location != 'México' && this.editedItem.location != 'Ecuador' && this.editedItem.location != 'Colombia') { //Work Auth, W4, i9, il
              if (this.editedItem.work_authorizationurl != 1 &&
                this.editedItem.w4url != 1 &&
                this.editedItem.i9url != 1 &&
                this.editedItem.ilurl != 1 &&
                this.editedItem.ssnurl != 1) {
                if (!this.work_authorization_File ||
                  !this.w4_File ||
                  !this.i9_File ||
                  !this.il_File ||
                  !this.ssn_File) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `All documents must be uploaded`,
                  });
                  return;
                }
              }
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `All documents must be uploaded`,
              });
              return
            }
          }
          if (this.editedItem.company === 'V-Outsourcing') {
            if (this.editedItem.location != 'México' && this.editedItem.location != 'Ecuador' && this.editedItem.location != 'Colombia') {//W9
              if (this.editedItem.w4url != 1) {
                if (!this.w9_File) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `All documents must be uploaded`,
                  });
                  return;
                }
              }
            } else {//RUC-RIMPE-SAT, Invoice example, Bank certificate
              if (this.editedItem.tax_certificateurl != 1 &&
                this.editedItem.invoice_exampleurl != 1 &&
                this.editedItem.bank_certificateurl != 1) {
                if (!this.tax_certificate_File ||
                  !this.invoice_example_File ||
                  !this.bank_certificate_File) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `All documents must be uploaded`,
                  });
                  return;
                }
              }
            }
          }
        }
        this.showLoader = true;
        this.generate_job_offer();
        return 'ok'
      } catch (error) {
        //console.error(error);
      }
    },
    async create_JobOffer_Pdf() {
      ////// PDF //////
      this.showLoader = true;
      this.jobOfferFile = null;
      const elementIds = ['employeeForm', 'accountingForm', 'otherDataForm', 'personalInformationForm',];
      const pdf = new jsPDF({
        unit: 'px' // Usar píxeles como unidad de medida
      });

      // Agregar la página inicial
      this.addInitialPage(pdf);

      for (let i = 0; i < elementIds.length; i++) {
        this.tab = i;
        await new Promise(resolve => {
          setTimeout(() => {
            resolve();
          }, 2000);
        });
        const elementId = elementIds[i];
        const element = document.getElementById(elementId);
        ////console.log("element: ", element);
        // Convertir el elemento a una imagen usando html2canvas
        const canvas = await html2canvas(element);

        // Obtener la imagen como base64
        const imgData = canvas.toDataURL('image/png');
        // Calcular el nuevo tamaño de la imagen al reducirlo al 50%
        const newWidth = canvas.width * 0.47;
        const newHeight = canvas.height * 0.5;
        // Definir el margen
        const margin = 10;
        // Agregar una nueva página para cada elemento
        pdf.addPage();
        pdf.internal.pageSize.height = newHeight + margin * 2;
        // Agregar la imagen al PDF con un margen
        pdf.addImage(imgData, 'PNG', margin, margin, newWidth, newHeight);
      }
      this.tab = 4;
      const pdfBlob = pdf.output('blob');

      const fileName = 'job_offer.pdf';
      const pdfFile = new File([pdfBlob], fileName, { type: 'application/pdf' });
      this.jobOfferFile = pdfFile;

      const pdfUrl = URL.createObjectURL(pdfBlob);
      ////console.log("Tipo de archivo:", pdfBlob.type);
      ////console.log("Tamaño del archivo Blob:", pdfBlob.size, "bytes");
      // Abrir el modal y pasar la URL del PDF como prop
      this.fileUrl = pdfUrl;
      this.modalTitle = "The form has been successfully filled out";
      this.formStatus = this.editedItem.form_status;
      this.modalOpen = true;
      this.showLoader = false;
      return 'ok'

      ////// PDF //////
    },
    pdf_to_blob(file) {
      const pdfBlob = file.output('blob');

      const fileName = file.filename;
      ////console.log("fileName: ", fileName);
      const pdfFile = new File([pdfBlob], fileName, { type: 'application/pdf' });

    },
    async addInitialPage(pdf) {
      pdf.setFontSize(42);
      pdf.setFont('helvetica', 'bold');

      // Calcular la anchura del texto "JOB OFFER"
      const textWidth = pdf.getStringUnitWidth('JOB OFFER') * pdf.internal.getFontSize() / pdf.internal.scaleFactor;

      // Calcular las coordenadas para centrar el texto
      const textX = (pdf.internal.pageSize.width - textWidth) / 2;
      const textY = (pdf.internal.pageSize.height - 42) / 2; // Centrado verticalmente

      // Agregar el texto al PDF
      pdf.text('JOB OFFER', textX, textY);
    },
    back() {
      this.tab--;
    },
    next() {
      this.tab++;
    },
    /* 
    Policy Modal
    this.signatureUrl -- URL from S3 Bucket
    this.signaturePad -- Signature painted in canvas
    */
    async openPolicyModal(title, fileName, file) {
      if (this.signatureUrl === '') {
        if (this.signaturePad === null) {
          alert("No signature, You must to sign")
          return;
        } else {
          if (this.signaturePad.isEmpty()) {
            alert("No signature, You must to sign");
            return;
          } else {
            this.get_signatureUrl(this.signaturePad);
          }
        }

      } else {
        ////console.log("Signature exists in s3 bucket")
      }
      const fileNameWithoutExtension = fileName.split('.').slice(0, -1).join('.');

      // Agregar 'url' al final del nombre del archivo
      const property = fileNameWithoutExtension + 'url';
      ////console.log("property: ", property);
      this.policy = property;
      ////console.log("policy: ", this.policy);
      ////console.log("this.editedItem[property]: ", this.editedItem[property]);
      this.statusPolicy = this.editedItem[property];
      try {
        this.policyModalTitle = title;
        /* const response = await this.generatePublicURL('hr', fileName); */
        const response = await generatePublicURL('hr', fileName);
        ////console.log("response: ", response);
        if (response.status === 404) {
          return
        }
        this.policyFileUrl = response.url;
        ////console.log("this.policyFileUrl: ", this.policyFileUrl);
        this.formStatus = this.editedItem.form_status;
        this.policyModalOpen = true;

      } catch (error) {
        //console.error("Error: ", error);
      }
    },
    async openModal(title, fileName, file) {
      ////console.log("<------------- openModal");
      ////console.log("fileName: ", fileName);
      const fileNameWithoutExtension = fileName.split('.').slice(0, -1).join('.');

      // Agregar 'url' al final del nombre del archivo
      const property = fileNameWithoutExtension + 'url';
      ////console.log("property: ", property);
      this.policy = property;
      ////console.log("policy: ", this.policy);
      ////console.log("this.editedItem[property]: ", this.editedItem[property]);
      try {
        this.modalTitle = title;
        if (this.editedItem[property] === true) {
          ////console.log("fileName: ", fileName);
          const folderName = `Employees/${this.editedItem.employee_number}`;
          /* const response = await this.generatePublicURL(folderName, fileName); */
          const response = await generatePublicURL(folderName, fileName);
          this.fileUrl = response.url;
          ////console.log("this.fileUrl: ", this.fileUrl);
          this.formStatus = this.editedItem.form_status;
          this.modalOpen = true;
        } else {
          ////console.log("file: ", file);
          if (file != null) {
            ////console.log("Diferente a null");
            const blob = new Blob([file], { type: file.type });
            // Crear una URL para el Blob
            this.fileUrl = URL.createObjectURL(blob);
            //console.log("this.fileUrl: ", this.fileUrl);
            //this.fileUrl = this.editedItem['file'];
            if (this.fileUrl) {
              this.modalOpen = true;
            }
          } else if (this.editedItem[property] === false || this.editedItem[property] === '') {
            alert("⚠️ No file loaded! Please load a file before proceeding.");
            /* const response = await generatePublicURL('hr', fileName);            
            if (response.status === 404) {
              return
            }
            this.fileUrl = response.url;            
            this.formStatus = this.editedItem.form_status;
            this.modalOpen = true; */
          }
        }
      } catch (error) {
        //console.error("Error: ", error);
      }


    },
    onlyNumbers(event) {
      const key = event.key;
      const allowedCharacters = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', '(', ')', '+', ' '];
      const isNumber = !isNaN(parseFloat(key)) && isFinite(key);
      const isAllowedCharacter = allowedCharacters.includes(key);

      // Permitir números del 0 al 9, teclas de navegación y caracteres permitidos
      if (!isNumber && !isAllowedCharacter) {
        event.preventDefault();
      }
    },
    async get_EmployeeData() {
      try {

        const employee_number = get_employee_number();
        ////console.log("employee_number: ", employee_number);
        const url = `${GET_EMPLOYEE_BY_EMPLOYEE_NUMBER}?employee_number=${employee_number}`;
        ////console.log("url: ", url);

        const response = await fetch(url); // Replace "your_endpoint" with the URL of your endpoint
        if (!response.ok) {
          throw new Error('Failed to fetch record');
        }
        const data = await response.json();
        return data; // Returns the object retrieved from the endpoint
      } catch (error) {
        //console.error('Error fetching record:', error);
        throw error;
      }
    },
    handleInfoFromParent(info) {
      // Manejar la información recibida desde el hijo
      ////console.log('Información recibida desde el padre:', info);
    },
    dialogTest() {
      //this.$emit('send-info', '¡Hola desde el hijo!');
      this.$emit('sent-info', false);
    },
    resetTimes(index) {
      if (!this.editedItem.days[index].checked) {
        this.editedItem.days[index].start_time = null;
        this.editedItem.days[index].end_time = null;
      }
    },
    shouldApplyGreenBackground(column, item) {
      // Verificar si la columna actual está en la lista de columnas verdes
      return this.greenColumns.includes(column);
    },
    getLocations() {
      ////console.log("Getting locations.....");
      fetch(LOCATIONS_ENDPOINT, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to fetch locations. Status: ' + response.status);
          }
          return response.json();
        })
        .then(data => {
          this.itemLocations = data;
          ////console.log(this.itemLocations);
          ////console.log(typeof this.itemLocations);
        })
        .catch(error => {
          console.error('An error occurred while fetching locations:', error);
          alert('An error occurred while fetching locations. Please try again later.');
        });
    },

    addScheduleProperties(employee) {
      employee.days.forEach(day => {
        //////console.log("Day: ", day.day_of_week);
        const dayOfWeekSchedule = day.day_of_week.toLowerCase() + "Schedule";
        if (day.start_time == null && day.end_time == null) {
          //////console.log("Adding blank property...............", dayOfWeekSchedule);
          employee[dayOfWeekSchedule] = "";
        } else {
          //////console.log("Adding data property...............", dayOfWeekSchedule);
          employee[dayOfWeekSchedule] = this.formatTime(day.start_time) + ' - ' + this.formatTime(day.end_time);
        }
      })
    },
    editItem(item) {
      this.editedIndex = this.employees.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogTabs = true;
    },
    deleteItem(item) {
      this.editedIndex = this.employees.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    close() {
      ////console.log("Closing in EmployeesCard......");
      this.$v.$reset();
      this.editedItem = JSON.parse(JSON.stringify(this.defaultItem));
      this.editedIndex = -1;
      //this.dialog = false;
      //this.dialogTabs = false;
      //this.dialogDelete = false;
      this.tab = null;
      //this.scrollToTop();
    },
    async uploadingDocuments() {
      //console.log("Uploadiing Documents.....");
      let folderName = this.editedItem.employee_number;
      const documents = [this.recentPhotoFile,
      this.bachellorDegreeFile,
      this.resumeFile,
      this.idFile,
      this.passportFile,
      //////////////////////////
      this.tax_certificate_File,
      this.invoice_example_File,
      this.bank_certificate_File,
      this.work_authorization_File,
      this.w4_File,
      this.i9_File,
      this.il_File,
      this.w9_File,
      this.ssn_File];
      //documents.forEach((document) => {
      // Upload files
      let response = null;
      for (const document of documents) {

        if (document != null) {
          const fileName = document.name.split(".")[0].toLowerCase();
          /* const response = await this.uploadFile(document, fileName, folderName); */
          try {
            response = await uploadFile(document, fileName, folderName);
            //console.log("response: ", response);
            //console.log(`${fileName}url`);

            this.editedItem[fileName + 'url'] = response.public_url;
          } catch (error) {
            //console.error("Error: ", error);
            return;
          }
        } else {
          ////console.log("No docs...");
        }
      };
      //console.log("---------response: ", response);
      if (response === null) {
        response = {
          ok: true,
          message: 'No doc to upload...'
        }
      }
      //console.log("UPLOADING FILE/response: ", response);
      return response;
    },
    async save() {
      //console.log("SAVING INFO.......");
      try {
        this.showLoader = true;
        /* UPDATING */
        // Updating INFO by HR
        console.log("xxxxx>>>>>>>>>>>>>>>>> this.editedIndex: ", this.editedIndex);
        if (this.editedIndex > -1) {
          console.log(">>>>>>>>>>>>>>>>> this.editedIndex: ", this.editedIndex);
          console.log("**************************");
          console.log("Updating....");
          console.log("**************************");
          ////console.log("Uploading files.......")
          const res = await this.uploadingDocuments();
          console.log("++++++++++++++++ uploadingDocuments/res: ", res);
          if (res.ok) {
            if (this.pageRoute == '/employeesform') {
              if (this.editedItem.form_status == false) {
                this.editedItem.form_status = true
                this.editedItem['job_offerurl'] = true;
                this.get_signatureUrl(this.signaturePad);
                this.upload_signature(this.signatureImage);
              }
            }
            this.clearFilesFields();
            try {
              //console.log("this.pageRoute: ", this.pageRoute);
              if (this.pageRoute === '/employeesform') {
                /* UPDATNG by EMPLOYEE */
                console.log("<<<<<<<<<<<<<< UPDATNG by EMPLOYEE......");

                const responseEmployee = await updateEmployee(this.editedItem);
                console.log("responseEmployee: ", responseEmployee);
                if (responseEmployee.ok) {
                  try {
                    const message = await sendJobOfferEmail(this.editedItem.employee_number);
                    Swal.fire({
                      icon: 'success',
                      title: 'Success!',
                      text: 'Profile and data complete',
                    });
                  } catch (error) {
                    Swal.fire({
                      icon: 'error',
                      title: 'Error',
                      text: error.message,
                    });
                  }
                }
              } else {
                /* UPDATING Employee by HR */
                console.log("<<<<<<<<<<<<<< UPDATING Employee by HR ......");
                //this.update_Employee();
                const responseEmployee = await updateEmployee(this.editedItem);
                if (responseEmployee.ok) {
                  this.$refs.form.resetValidation(); // Restart validations
                  // Mostrar mensaje con Swal y luego enviar el email
                  /* Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Profile and data complete',
                  }).then(async () => {
                    try {
                      const message = await sendJobOfferEmail(this.editedItem.employee_number);
                      Swal.fire({
                        icon: 'success',
                        title: 'Email Sent',
                        text: message,
                      });
                    } catch (error) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error.message,
                      });
                    }
                  }); */
                  Swal.fire({
                    icon: 'success',
                    title: 'Success!',
                    text: 'Employee successfully edited',
                  }).then(async () => {
                    if (this.pageRoute != '/employeesform') {
                      this.$emit('sent-info', false);
                    }
                  });
                }
              }
            } catch (error) {
              //console.error("error: ", error);
            }
            this.showLoader = false;
          }
          // Updating

        } else {
          // Updating INFO By Employee

          console.log("**************************");
          console.log("Adding NEW employee......");
          console.log("**************************");
          // Add a new Employee
          this.add_newEmployee();
          this.showLoader = false;
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: 'Employee successfully added',
          }).then(() => {
            // Este código se ejecuta después de que el usuario cierra el diálogo de SweetAlert
            this.$emit('sent-info', false);
          });
          //this.$emit('sent-info', false);
        }
        //////console.log("After adding employee.....")
        if (this.pageRoute != '/employeesform') {
          this.close();
        }
      } catch (error) {
        //console.error("Error: ", error);
      }
    },
    clearFilesFields() {
      //Documents
      this.recentPhotoFile = null;
      this.bachellorDegreeFile = null;
      this.resumeFile = null;
      this.idFile = null;
      this.passportFile = null;
      this.tax_certificate_File = null;
      this.invoice_example_File = null;
      this.bank_certificate_File = null;
      this.work_authorization_File = null;
      this.w4_File = null;
      this.i9_File = null;
      this.il_File = null;
      this.w9_File = null;
      this.ssn_File = null;
    },
    async add_newEmployee() {
      try {
        var datos = this.editedItem;
        console.log("<<<<<<<<<<<<<<<<< datos: ", datos);
        const response = await fetch(ADD_NEW_EMPLOYEE, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(datos),
        });

        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(`Error al guardar el registro: ${errorMessage}`);
        }

        const responseData = await response.json();
        this.editedItem = responseData;
        ////console.log("editedItem: ", this.editedItem);

      } catch (error) {
        //console.error(error.message);
      }
    },
    update_StatusEmployee() {
      const data = {
        employee_number: this.editedItem.employee_number,
        status: 'Inactive'
      };

      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      };

      fetch(CHANGE_STATUS_EMPLOYEE, requestOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to update employee status');
          }
          return response.json();
        })
        .catch(error => {
          alert('An error occurred while updating employee status. Please try again later.');
        });
    },
    validateField(fieldName) {
      //////console.log("Validadando: ", fieldName);
      const errors = [];
      if (fieldName != 'phone_8x8' && fieldName != 'extension_phone_8x8' && fieldName != 'danubanet_name_1' && fieldName != 'danubanet_name_2') {
        if (!this.$v.editedItem[fieldName].$dirty && !this.$v.editedItem[fieldName].required) {
          //////console.log("-----REQUIRED-DIRTY.....")
          return errors; // No se muestra ningún error si el campo no ha sido tocado y no es requerido
        }
        if (!this.$v.editedItem[fieldName].required && !this.$v.editedItem[fieldName].$model) {
          ////console.log("-----REQUIRED.....", fieldName);
          errors.push(`${fieldName.replace(/_/g, ' ')} is required.`);
        }
      }
      return errors;
    },
    async onFileSelected(property, fileName) {
      console.log("property: ", property);
      console.log("fileName: ", fileName);
      try {
        if (this.validateExtension(this[property])) {
          this[property] = this.renamedFile(this[property], fileName);
        }

      } catch (error) {
        console.error("ErroronFileSelected: ", error);
      }
    },
    validateExtension(file) {
      const allowedExtensions = ['pdf'];
      //const file = this.recentPhotoFile;    

      // Obtener la extensión del archivo
      const extension = file.name.split('.').pop().toLowerCase();

      if (!allowedExtensions.includes(extension)) {
        // Si la extensión del archivo no está permitida, mostrar una alerta
        alert("El archivo debe ser un PDF.");
        return false;
      } else {
        return true;
      }
    },
    renamedFile(file, newName) {
      //////console.log("Rename File...")
      if (file === null) {
        ////console.log("Archivo vacío.................");
      } else {
        ////console.log("Archivo NO vacío.......");
      }
      const originalFileName = file.name;
      ////console.log("originalFileName: ", originalFileName);
      const fileExtension = originalFileName.slice((originalFileName.lastIndexOf(".") - 1 >>> 0) + 2);
      //////console.log("fileExtension: ", fileExtension);
      // Create a new file with the desired name and the same file type
      const renamedFile = new File([file], newName.toLowerCase() + '.' + fileExtension, { type: file.type });
      //////console.log("renamedFile: ", renamedFile);
      return renamedFile;
    },
  },
}
</script>

<style>
.signature-pad-container {
  border: 1px solid #e8e8e8;
  border-radius: 5px;
  width: 100%;
  /* Ajusta el ancho del contenedor al 100% */
  max-width: 1000px;
  /* Establece un ancho máximo si es necesario */
  height: 200px;
  /* Establece una altura fija o ajusta según tus necesidades */
  margin-top: 20px;
  overflow: hidden;
  /* Asegura que el contenido no se desborde */
}

.signature-pad-container canvas {
  width: 100%;
  /* Ajusta el ancho del canvas al 100% del contenedor */
  height: 100%;
  /* Ajusta la altura del canvas al 100% del contenedor */
  display: block;
  /* Asegura que el canvas se muestre como un bloque */
}

.d-flex {
  display: flex;
}

.align-items-end {
  align-items: flex-end;
  /* Alinea los elementos en la parte inferior */
}

.m-0 {
  margin: 0;
  /* Remueve márgenes del h4 */
}

.ml-2 {
  margin-left: 8px;
  /* Ajusta el margen izquierdo si es necesario */
}

.p-0 {
  padding: 0;
  /* Remueve padding del botón para evitar desalineación */
}

/* 
  Style adjustments for the data table:
  - Table headers will not wrap text and will display all content in a single line.
  - Table cells will wrap text if it's too long but will maintain a minimum width of 170px.
  */
@media print {

  /* Estilos para la vista de impresión */
  body * {
    visibility: hidden;
  }

  #employeeForm,
  #employeeForm * {
    visibility: visible;
  }

  #employeeForm {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.purple--text {
  color: purple;
}

.green--text {
  color: green;
}

.red--text {
  color: red;
}

.v-data-table th {
  white-space: nowrap;
}

.v-data-table td {
  /*word-wrap: break-word;*/
  white-space: nowrap;
  /* min-width: 170px; */
}

.custom-row {
  margin-bottom: -8px;
  /* Puedes ajustar este valor según sea necesario */
}

h4 {
  font-weight: bold;
  color: black;
}

h1 {
  color: orange;
}

.green-background {
  background-color: #C8E6C9;
  /* Puedes cambiar este color si es necesario */
}

/*Styles for loader*/
/* HTML: <div class="loader"></div> */
.loader-container {
  position: fixed;
  /* Para que el loader esté por encima de todo el contenido */
  top: 0;
  left: 0;
  width: 100%;
  /* Ocupa todo el ancho del viewport */
  height: 100%;
  /* Ocupa todo el alto del viewport */
  display: flex;
  justify-content: center;
  /* Centra horizontalmente */
  align-items: center;
  /* Centra verticalmente */
  background-color: rgba(255, 255, 255, 0.5);
  /* Fondo semitransparente */
  z-index: 9999;
  /* Coloca el loader por encima de todo el contenido */
}

.loader {
  width: 120px;
  height: 60px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  background: #fff;
  filter: blur(5px) contrast(10) hue-rotate(60deg);
  mix-blend-mode: darken;
}

.loader:before,
.loader:after {
  content: "";
  width: 40px;
  border-radius: 50%;
  background: #ff00ff;
  animation: l3 1s infinite alternate;
}

.loader:after {
  --s: -1;
}

@keyframes l3 {

  90%,
  100% {
    transform: translate(calc(var(--s, 1)*30px))
  }
}
</style>