const itemDepartments = ["Sales Department",
    "Retentions Department",
    "Calendar Department",
    "Human Resources Department",
    "Accounting Department",
    "Education Department",
    "Corporate Department",
    "Marketing Department",
    "Learning and Development Department",
    "Information Technology Department",
    "Fleet Department",
    "General/Prorated",
    "Wellness"]


const reporting_tags = {
    "Departments": {
        "tag_id": "2103882000000000337",
        "associated_with": "item",
        "is_active": true,
        "is_tag_mandatory": false,
        "tag_options": [
            { "id": 1, "name": "Accounting", "tag_id": "2103882000000000337", "tag_option_id": "2103882000002009011" },
            { "id": 2, "name": "Admin", "tag_id": "2103882000000000337", "tag_option_id": "2103882000003663085" },
            { "id": 3, "name": "Calendar", "tag_id": "2103882000000000337", "tag_option_id": "2103882000009850109" },
            { "id": 4, "name": "Events", "tag_id": "2103882000000000337", "tag_option_id": "2103882000029076005" },
            { "id": 5, "name": "Financial", "tag_id": "2103882000000000337", "tag_option_id": "2103882000010528881" },
            { "id": 6, "name": "Fleet", "tag_id": "2103882000000000337", "tag_option_id": "2103882000002009013" },
            { "id": 7, "name": "HR", "tag_id": "2103882000000000337", "tag_option_id": "2103882000003599061" },
            { "id": 8, "name": "IT", "tag_id": "2103882000000000337", "tag_option_id": "2103882000005853027" },
            { "id": 9, "name": "Legal", "tag_id": "2103882000000000337", "tag_option_id": "2103882000002009009" },
            { "id": 10, "name": "Marketing & Sales (publicidad, bonos cumplimientos, meals)", "tag_id": "2103882000000000337", "tag_option_id": "2103882000002009007" },
            { "id": 11, "name": "Mechanic", "tag_id": "2103882000000000337", "tag_option_id": "2103882000025509044" },
            { "id": 12, "name": "Operations", "tag_id": "2103882000000000337", "tag_option_id": "2103882000002009015" },
            { "id": 13, "name": "Supplies", "tag_id": "2103882000000000337", "tag_option_id": "2103882000021109003" },
            { "id": 14, "name": "Wellness", "tag_id": "2103882000000000337", "tag_option_id": "2103882000031415061" }
        ],
        "status": "active"
    },
    "Instructors": {
        "tag_id": "2103882000000000345",
        "associated_with": "item",
        "is_active": false,
        "is_tag_mandatory": false,
        "tag_options": ["Aldalo Ismail"],
        "status": "inactive"
    },
    "Location": {
        "tag_id": "2103882000000000333",
        "associated_with": "item",
        "is_active": true,
        "is_tag_mandatory": false,
        "tag_options": [
            { "id": 1, "name": "26th", "tag_id": "2103882000000000333", "tag_option_id": "2103882000001984014" },
            { "id": 2, "name": "All Locations", "tag_id": "2103882000000000333", "tag_option_id": "2103882000021114027" },
            { "id": 3, "name": "Aurora", "tag_id": "2103882000000000333", "tag_option_id": "2103882000024636575" },
            { "id": 4, "name": "Bucktown (Main Office)", "tag_id": "2103882000000000333", "tag_option_id": "2103882000020348161" },
            { "id": 5, "name": "Classrooms / Residence", "tag_id": "2103882000000000333", "tag_option_id": "2103882000020577019" },
            { "id": 6, "name": "Corporate off site", "tag_id": "2103882000000000333", "tag_option_id": "2103882000019987007" },
            { "id": 7, "name": "Evanston", "tag_id": "2103882000000000333", "tag_option_id": "2103882000001984016" },
            { "id": 8, "name": "Fullerton", "tag_id": "2103882000000000333", "tag_option_id": "2103882000009068036" },
            { "id": 9, "name": "Hyde Park", "tag_id": "2103882000000000333", "tag_option_id": "2103882000001984012" },
            { "id": 10, "name": "Irving Park", "tag_id": "2103882000000000333", "tag_option_id": "2103882000012154003" },
            { "id": 11, "name": "Jefferson Park", "tag_id": "2103882000000000333", "tag_option_id": "2103882000013098555" },
            { "id": 12, "name": "Norridge", "tag_id": "2103882000000000333", "tag_option_id": "2103882000013676007" },
            { "id": 13, "name": "Oak Park", "tag_id": "2103882000000000333", "tag_option_id": "2103882000019359007" },
            { "id": 14, "name": "Off Site", "tag_id": "2103882000000000333", "tag_option_id": "2103882000002561011" },
            { "id": 15, "name": "On Line", "tag_id": "2103882000000000333", "tag_option_id": "2103882000004036003" },
            { "id": 16, "name": "Western", "tag_id": "2103882000000000333", "tag_option_id": "2103882000001984010" },
            { "id": 17, "name": "Western 2058", "tag_id": "2103882000000000333", "tag_option_id": "2103882000009068034" },
            { "id": 17, "name": "Mexico", "tag_id": "2103882000000000333", "tag_option_id": "2103882000031415009" },            
            { "id": 18, "name": "PHP", "tag_id": "2103882000000000333", "tag_option_id": "2103882000031415097" },            
            { "id": 19, "name": "USA", "tag_id": "2103882000000000333", "tag_option_id": "2103882000031415095" },            
            { "id": 20, "name": "EUROPA", "tag_id": "2103882000000000333", "tag_option_id": "2103882000031415099" },            
            { "id": 21, "name": "Venezuela", "tag_id": "2103882000000000333", "tag_option_id": "2103882000031415091" },          
            { "id": 22, "name": "Colombia", "tag_id": "2103882000000000333", "tag_option_id": "2103882000031415093" },
        ],
        "status": "active"
    },
    "Owners": {
        "tag_id": "2103882000000000351",
        "associated_with": "item",
        "is_active": true,
        "is_tag_mandatory": false,
        "tag_options": [
            "Carlos Villegas", "Diana Villegas", "Elvia Villegas", "Jenny Villegas", "Mayra Khan",
            "Travel expenses"
        ],
        "status": "active"
    },
    "Program BTW": {
        "tag_id": "2103882000000000341",
        "associated_with": "item",
        "is_active": true,
        "is_tag_mandatory": false,
        "tag_options": ["CDL", "DL", "Lic C", "Motorcycle", "Stick Shift", "Teens"],
        "status": "active"
    },
    "Program NCL": {
        "tag_id": "2103882000000000343",
        "associated_with": "item",
        "is_active": true,
        "is_tag_mandatory": false,
        "tag_options": ["ADE", "CDL", "CHL", "DDC", "DL", "Lic C", "Motorcycle", "Teens", "WE"],
        "status": "active"
    },
    "Programs Revenue": {
        "tag_id": "2103882000000000335",
        "associated_with": "item",
        "is_active": true,
        "is_tag_mandatory": false,
        "tag_options": ["Adults", "Teens"],
        "status": "active"
    },
    "Responsable": {
        "tag_id": "2103882000000000339",
        "associated_with": "item",
        "is_active": true,
        "is_tag_mandatory": false,
        "tag_options": ["DV", "FN", "JR", "JV", "MK"],
        "status": "active"
    },
    "Type Refund": {
        "tag_id": "2103882000000000349",
        "associated_with": "item",
        "is_active": true,
        "is_tag_mandatory": false,
        "tag_options": ["Authorizer", "Check", "Zelle"],
        "status": "active"
    },
    "Vehicles": {
        "tag_id": "2103882000000000347",
        "associated_with": "item",
        "is_active": true,
        "is_tag_mandatory": false,
        "tag_options": [
            {
                "id": 1,
                "name": "Chevy 20",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833017"
            },
            {
                "id": 2,
                "name": "Chevy 21",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000006042852"
            },
            {
                "id": 3,
                "name": "Class C Truck 1",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833063"
            },
            {
                "id": 4,
                "name": "Kia 10",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833065"
            },
            {
                "id": 5,
                "name": "Kia 11",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833021"
            },
            {
                "id": 6,
                "name": "Kia 12",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833067"
            },
            {
                "id": 7,
                "name": "Kia 13",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833069"
            },
            {
                "id": 8,
                "name": "Kia 14",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833071"
            },
            {
                "id": 9,
                "name": "Kia 15",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833073"
            },
            {
                "id": 10,
                "name": "Kia 16",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833075"
            },
            {
                "id": 11,
                "name": "Kia 17",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833077"
            },
            {
                "id": 12,
                "name": "Kia 18",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833079"
            },
            {
                "id": 13,
                "name": "Kia 19",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833081"
            },
            {
                "id": 14,
                "name": "Kia 2",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833025"
            },
            {
                "id": 15,
                "name": "Kia 22",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833007"
            },
            {
                "id": 16,
                "name": "KIA 23",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833009"
            },
            {
                "id": 17,
                "name": "Kia 24",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833011"
            },
            {
                "id": 18,
                "name": "Kia 25",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833013"
            },
            {
                "id": 19,
                "name": "Kia 26",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833059"
            },
            {
                "id": 20,
                "name": "Kia 27",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000004036011"
            },
            {
                "id": 21,
                "name": "Kia 28",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000021752107"
            },
            {
                "id": 22,
                "name": "Kia 29",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000004036015"
            },
            {
                "id": 23,
                "name": "Kia 3",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833029"
            },
            {
                "id": 24,
                "name": "Kia 30",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833053"
            },
            {
                "id": 25,
                "name": "Kia 31",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000004036019"
            },
            {
                "id": 26,
                "name": "Kia 32",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833047"
            },
            {
                "id": 27,
                "name": "Kia 33",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833055"
            },
            {
                "id": 28,
                "name": "Kia 34",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833057"
            },
            {
                "id": 29,
                "name": "Kia 35",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000006042848"
            },
            {
                "id": 30,
                "name": "Kia 36",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000006441573"
            },
            {
                "id": 31,
                "name": "Kia 37",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000006463277"
            },
            {
                "id": 32,
                "name": "Kia 38",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000007407427"
            },
            {
                "id": 33,
                "name": "Kia 39",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000007420081"
            },
            {
                "id": 34,
                "name": "Kia 4",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833015"
            },
            {
                "id": 35,
                "name": "Kia 40",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000007871210"
            },
            {
                "id": 36,
                "name": "Kia 41",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000007985007"
            },
            {
                "id": 37,
                "name": "Kia 42",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000007985011"
            },
            {
                "id": 38,
                "name": "Kia 43",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000007985013"
            },
            {
                "id": 39,
                "name": "Kia 44",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000008275025"
            },
            {
                "id": 40,
                "name": "Kia 45",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000008275029"
            },
            {
                "id": 41,
                "name": "Kia 46",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000008275031"
            },
            {
                "id": 42,
                "name": "Kia 47",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000008275033"
            },
            {
                "id": 43,
                "name": "Kia 48",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000008275035"
            },
            {
                "id": 44,
                "name": "Kia 49",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000008275037"
            },
            {
                "id": 45,
                "name": "Kia 5",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833019"
            },
            {
                "id": 46,
                "name": "Kia 50",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000008275039"
            },
            {
                "id": 47,
                "name": "Kia 51",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000008471157"
            },
            {
                "id": 48,
                "name": "Kia 52",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000008471161"
            },
            {
                "id": 49,
                "name": "Kia 53",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000008471165"
            },
            {
                "id": 50,
                "name": "Kia 54",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000008471167"
            },
            {
                "id": 51,
                "name": "Kia 55",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000008471169"
            },
            {
                "id": 52,
                "name": "Kia 56",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000010699305"
            },
            {
                "id": 53,
                "name": "Kia 57",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000010699309"
            },
            {
                "id": 54,
                "name": "Kia 58",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000013366141"
            },
            {
                "id": 55,
                "name": "Kia 59",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000013366145"
            },
            {
                "id": 56,
                "name": "Kia 6",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833023"
            },
            {
                "id": 57,
                "name": "Kia 60",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000019654013"
            },
            {
                "id": 58,
                "name": "Kia 61",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000023069635"
            },
            {
                "id": 59,
                "name": "Kia 62",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000023069639"
            },
            {
                "id": 60,
                "name": "Kia 63",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000024538003"
            },
            {
                "id": 61,
                "name": "Kia 7",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833027"
            },
            {
                "id": 62,
                "name": "Kia 8",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833031"
            },
            {
                "id": 63,
                "name": "Kia 9",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833033"
            },
            {
                "id": 64,
                "name": "KIA SS",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000024717003"
            },
            {
                "id": 65,
                "name": "Motorcycle 1",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833035"
            },
            {
                "id": 66,
                "name": "Motorcycle 2",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833037"
            },
            {
                "id": 67,
                "name": "Motorcycle 3",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833039"
            },
            {
                "id": 68,
                "name": "Motorcycle 4",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000004090168"
            },
            {
                "id": 69,
                "name": "NISSAN SS",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000020348157"
            },
            {
                "id": 70,
                "name": "Nissan Stick Shift 2",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833061"
            },
            {
                "id": 71,
                "name": "Stick Shift 1",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000006441045"
            },
            {
                "id": 72,
                "name": "Trailer #1",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833041"
            },
            {
                "id": 73,
                "name": "Transit",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000006441415"
            },
            {
                "id": 74,
                "name": "Trax 20",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833043"
            },
            {
                "id": 75,
                "name": "Trax 21",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000006042850"
            },
            {
                "id": 76,
                "name": "TRUCK",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833045"
            },
            {
                "id": 77,
                "name": "Van",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833049"
            },
            {
                "id": 78,
                "name": "Volkswagen",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000003833051"
            },
            {
                "id": 79,
                "name": "Hino Truck",
                "tag_id": "2103882000000000347",
                "tag_option_id": "2103882000033478003"
            }
        ],
        "status": "active"
    }
}

export function getItemDepartments() {
    return itemDepartments;
}

export function getReportingTags() {
    return reporting_tags;
}