<template>
    <crm-permissions />
</template>

<script>
import crmPermissions from '../../components/Permissions/crmPermissions.vue'


export default {
    name: 'crmPermissionsView',
    components: {
        crmPermissions,
    },
}
</script>