<template>
  <div class="table-container">
    <v-data-table :headers="headers" :items="filteredEmployees" :fixed-header="true" class="fixed-headers fixed-columns"
      height="500px">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Permissions</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-text-field v-model="search" label="Search" solo-inverted prepend-icon="mdi-magnify"
            class="mr-3 mt-5"></v-text-field>
          <v-btn color="blue" dark class="mb-2 mr-2" @click="getAllEmployees">
            <v-icon left>mdi-refresh</v-icon>
            Refresh
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.walk_ins="{ item }">
        <v-switch v-model="item.walk_ins" @change="toggleItem(item, 'walk_ins')"
          :color="item.walk_ins ? 'green' : 'red'" :class="item.walk_ins ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>
      <template v-slot:item.job_offer="{ item }">
        <v-switch v-model="item.job_offer" @change="toggleItem(item, 'job_offer')"
          :color="item.job_offer ? 'green' : 'red'" :class="item.job_offer ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>
      <template v-slot:item.training_logs="{ item }">
        <v-switch v-model="item.training_logs" @change="toggleItem(item, 'training_logs')"
          :color="item.training_logs ? 'green' : 'red'" :class="item.training_logs ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>
      <template v-slot:item.nova_in_out="{ item }">
        <v-switch v-model="item.nova_in_out" @change="toggleItem(item, 'nova_in_out')"
          :color="item.nova_in_out ? 'green' : 'red'" :class="item.nova_in_out ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>
      <template v-slot:item.hr="{ item }">
        <v-switch v-model="item.hr" @change="toggleItem(item, 'hr')" :color="item.hr ? 'green' : 'red'"
          :class="item.hr ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>
      <template v-slot:item.hr_philippines="{ item }">
        <v-switch v-model="item.hr_philippines" @change="toggleItem(item, 'hr_philippines')"
          :color="item.hr_philippines ? 'green' : 'red'" :class="item.hr_philippines ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>
      <template v-slot:item.accounting="{ item }">
        <v-switch v-model="item.accounting" @change="toggleItem(item, 'accounting')"
          :color="item.accounting ? 'green' : 'red'" :class="item.accounting ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>
      <template v-slot:item.accounting_validation="{ item }">
        <v-switch v-model="item.accounting_validation" @change="toggleItem(item, 'accounting_validation')"
          :color="item.accounting_validation ? 'green' : 'red'"
          :class="item.accounting_validation ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>
      <template v-slot:item.marketing="{ item }">
        <v-switch v-model="item.marketing" @change="toggleItem(item, 'marketing')"
          :color="item.marketing ? 'green' : 'red'" :class="item.marketing ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>
      <template v-slot:item.fleet="{ item }">
        <v-switch v-model="item.fleet" @change="toggleItem(item, 'fleet')" :color="item.fleet ? 'green' : 'red'"
          :class="item.fleet ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>
      <template v-slot:item.it="{ item }">
        <v-switch v-model="item.it" @change="toggleItem(item, 'it')" :color="item.it ? 'green' : 'red'"
          :class="item.it ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>

      <template v-slot:item.activity_report="{ item }">
        <v-switch v-model="item.activity_report" @change="toggleItem(item, 'activity_report')"
          :color="item.activity_report ? 'green' : 'red'" :class="item.activity_report ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>

      <template v-slot:item.sales="{ item }">
        <v-switch v-model="item.sales" @change="toggleItem(item, 'sales')" :color="item.sales ? 'green' : 'red'"
          :class="item.sales ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>

      <template v-slot:item.nova_exam="{ item }">
        <v-switch v-model="item.nova_exam" @change="toggleItem(item, 'nova_exam')"
          :color="item.nova_exam ? 'green' : 'red'" :class="item.nova_exam ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>

      <template v-slot:item.nova_crm="{ item }">
        <v-switch v-model="item.nova_crm" @change="toggleItem(item, 'nova_crm')"
          :color="item.nova_crm ? 'green' : 'red'" :class="item.nova_crm ? 'switch-green' : 'switch-red'">
        </v-switch>
      </template>

    </v-data-table>
    <div class="loader-container" v-if="showLoader">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
import { UPDATE_PERMISSIONS } from '../../utils/endpoints';
import { getPermissions, updatePermissions } from '../../utils/Permissions/permissions_CRUD';
import { getCrmPermissions, updateCrmPermissions } from '../../utils/Permissions/Crm/crm_permissions_CRUD';
import { bus } from '../../main.js';

export default {
  name: 'PermissionsConsole',
  data: () => ({
    showLoader: false,
    employees: [],
    search: '',

    headers: [
      {
        text: 'Employee number',
        align: 'start',
        sortable: false,
        value: 'employee_number',
      },
      { text: 'Name(s)', value: 'name' },
      { text: 'Last name(s)', value: 'last_name' },
      { text: 'Department', value: 'department' },
      { text: 'Permissions', value: 'permissions' },
      { text: 'Position', value: 'position' },
      //{ text: 'Nova Email', value: 'nova_email' },
      //{ text: 'Company', value: 'company' },
      { text: 'Walk_ins', value: 'walk_ins', sortable: false },
      { text: 'Job Offer', value: 'job_offer', sortable: false },
      { text: 'Training Log', value: 'training_logs', sortable: false },
      { text: 'Nova In Out', value: 'nova_in_out', sortable: false },
      { text: 'HR', value: 'hr', sortable: false },
      { text: 'HR Philippines', value: 'hr_philippines', sortable: false },
      { text: 'Accounting', value: 'accounting', sortable: false },
      { text: 'Accounting Validation', value: 'accounting_validation', sortable: false },
      { text: 'Marketing', value: 'marketing', sortable: false },
      { text: 'Fleet', value: 'fleet', sortable: false },
      { text: 'IT', value: 'it', sortable: false },
      { text: 'Activity Report', value: 'activity_report', sortable: false },
      { text: 'Sales', value: 'sales', sortable: false },
      { text: 'Nova Exam', value: 'nova_exam', sortable: false },
      { text: 'Nova CRM', value: 'nova_crm', sortable: false },
    ],
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Employee' : 'Edit Employee'
    },
    /* filteredEmployees() {
      return this.employees.filter(employee =>
        employee.name.toLowerCase().includes(this.search.toLowerCase())
      );
    }, */
    filteredEmployees() {
      if (this.employees) {
        return this.employees.filter(employee => {
          const nameMatch = employee.name.toLowerCase().includes(this.search.toLowerCase());
          const lastNameMatch = employee.last_name.toLowerCase().includes(this.search.toLowerCase());
          return nameMatch || lastNameMatch;
        });
      } else {
        return [];
      }
    },
  },
  async mounted() {
    bus.$emit('login-success');
    bus.$emit('permissions');
    //this.employees = await getPermissions();
    //console.log("this.employees: ", this.employees);
    await this.getAllEmployees();
  },
  methods: {
    async toggleItem(Obj, item) {
      console.log("<<<<< item: ", item);
      console.log("<<<<< Obj: ", Obj);
      if (Obj[item]) {
        console.log("ON");
      } else {
        console.log("OFF")
      }
      const data = {
        "employee_number": Obj['employee_number'],
        [item]: Obj[item]
      }
      console.log("data: ", data)

      if (item === 'nova_crm') {
        await updateCrmPermissions(data);
      } else {
        await updatePermissions(data)
      }
      //this.update_PermissionsEmployee(data);
    },

    async getAllEmployees() {
      this.showLoader = true;
      console.log("Getting all employees.....")
      this.employees = await getPermissions();
      this.showLoader = false;
    },
    update_PermissionsEmployee(data) {
      const requestOptions = {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      };

      fetch(UPDATE_PERMISSIONS, requestOptions)
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to update employee status');
          }
          return response.json();
        })
        .catch(error => {
          alert('An error occurred while updating employee status. Please try again later.');
          this.getAllEmployees();
        });
    },
  },
}
</script>

<style scoped>
/* Contenedor principal con overflow */
.table-container {
  overflow: auto;
  max-width: 100%;
}

/* HEADERS FIJOS - Todos los nombres de columnas */
.fixed-headers >>> thead tr th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  box-shadow: 0 2px 2px -1px rgba(0,0,0,0.1);
}

/* PRIMERAS 3 COLUMNAS FIJAS */
.fixed-columns >>> tbody tr td:nth-child(1),
.fixed-columns >>> tbody tr td:nth-child(2),
.fixed-columns >>> tbody tr td:nth-child(3) {
  position: sticky;
  background-color: white;
  z-index: 5;
}

/* HEADERS de las primeras 3 columnas (doble sticky) */
.fixed-columns >>> thead tr th:nth-child(1),
.fixed-columns >>> thead tr th:nth-child(2),
.fixed-columns >>> thead tr th:nth-child(3) {
  position: sticky;
  top: 0;  
  left: auto;
  z-index: 15; 
}

/* Posicionamiento horizontal */
.fixed-columns >>> tbody tr td:nth-child(1),
.fixed-columns >>> thead tr th:nth-child(1) {
  left: 0;
  min-width: 120px;
}

.fixed-columns >>> tbody tr td:nth-child(2),
.fixed-columns >>> thead tr th:nth-child(2) {
  left: 120px;
  min-width: 150px;
}

.fixed-columns >>> tbody tr td:nth-child(3),
.fixed-columns >>> thead tr th:nth-child(3) {
  left: 270px;
  min-width: 150px;
  box-shadow: 2px 0 3px rgba(0,0,0,0.1);
}
</style>


<!-- Estilos GLOBALES para los switches (deben ir sin scoped) -->
<style>
.switch-green .v-input--switch__track {
  background-color: #77dd77 !important;
}

.switch-green .v-input--switch__thumb {
  background-color: #77dd77 !important;
}

.switch-red .v-input--switch__track {
  background-color: #FF9999 !important;
}

.switch-red .v-input--switch__thumb {
  background-color: #FF9999 !important;
}

/* 
  Style adjustments for the data table:
  - Table headers will not wrap text and will display all content in a single line.
  - Table cells will wrap text if it's too long but will maintain a minimum width of 170px.
  */
.v-data-table th {
  white-space: nowrap;
}

.v-data-table td {
  /*word-wrap: break-word;*/
  white-space: nowrap;
  /* min-width: 170px; */
}

/* Loader */
.loader-container {
  position: fixed;
  /* Para que el loader esté por encima de todo el contenido */
  top: 0;
  left: 0;
  width: 100%;
  /* Ocupa todo el ancho del viewport */
  height: 100%;
  /* Ocupa todo el alto del viewport */
  display: flex;
  justify-content: center;
  /* Centra horizontalmente */
  align-items: center;
  /* Centra verticalmente */
  background-color: rgba(255, 255, 255, 0.5);
  /* Fondo semitransparente */
  z-index: 9999;
  /* Coloca el loader por encima de todo el contenido */
}

.loader {
  width: 120px;
  height: 60px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  background: #fff;
  filter: blur(5px) contrast(10) hue-rotate(60deg);
  mix-blend-mode: darken;
}

.loader:before,
.loader:after {
  content: "";
  width: 40px;
  border-radius: 50%;
  background: #ff00ff;
  animation: l3 1s infinite alternate;
}

.loader:after {
  --s: -1;
}

@keyframes l3 {

  90%,
  100% {
    transform: translate(calc(var(--s, 1)*30px))
  }
}
</style>