import { PERMISSIONS } from '../../../src/utils/endpoints.js'


export async function getPermissions() {
    try {
        const response = await fetch(PERMISSIONS, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        const data = await response.json();
        console.log('Permissions:', data);
        return data;
    } catch (error) {
        console.error('Failed to fetch permissions:', error);
        return null;
    }
}

export async function updatePermissions(updatedData) {
    try {
        const response = await fetch(PERMISSIONS, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatedData)
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        const data = await response.json();
        console.log('Updated Permission:', data);
        return data;
    } catch (error) {
        console.error('Failed to update permission:', error);
        return null;
    }
}